@media screen and (min-width: 768px) {
    .main-order-section{
        display: block;
    }
    .order-section-container{
    
        padding: 70px 60px 50px 60px;
    }

    .order-igor-mob-img{
    display:none;
}
.order-igor-tab-img{
    display: block;
    border-radius: 4px;
    background: lightgray url('../images/order-igor-tab_2x.webp') no-repeat center center / cover;
    width: 199px;
    height: 270px;
}

.page-form{
    gap:0px;
    h2{
        margin-bottom: 8px;
    }
    h3{
        margin-bottom: 24px;
    }
}

.order-form-flex-container{
    display: flex;
 gap:24px;
}
.form-order-form{
    width: 425px;
}
.page-form-socials{
    background: transparent;
}
.page-form-label{
    font-size: 15px;
}
.form-input{
    font-size: 12px;
}
.page-form-btn{
    margin-top:22px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
}
.page-form-btn:hover{
    background: var(--button-hover-gradient);
    color:var(--header-white-color);
    cursor: pointer;
    svg{
      fill:var(--header-white-color);
    }
}

.page-form-socials{
    margin-top:24px;
    padding: 0;
    width: 508px;
    margin-right: auto;
    margin-left: auto;
}

.page-form-social-title{
    margin-left: 4px;
}
.service-item-bottom-text {
    font-size: 15px;
    font-weight: 400;
    color:var(--black-button-color);
}


}