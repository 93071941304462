footer {
  background: var(--footer-background-color);
}
.footer-container {
  display: flex;
  width: 320px;
  padding: 14px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
}


.all-rights h3,
.socials h3,
.logo-slogan {
  color: var(--main-white-color);
  font-family: 'Roboto';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* 12px */
  letter-spacing: 0.1px;
}
.socials h3{
    margin-bottom: 8px;
    text-align: center;
}
.logo-slogan {
    margin-top: 8px;
    text-align: center;
    font-size: 10px;
}

.social-icons {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.s-icon {
  width: 20px;
  height: 20px;
}
.hidden-viber{
  display:none;
}
.footer-nav-container{
  display:none;
}
.all-rights{
  display: flex;
  gap:20px;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 14px;
  h3 a {
    color: inherit; 
    text-decoration: none; 
  }
  h3 a:hover {
    text-decoration: underline; /* Добавить подчеркивание при наведении */
  cursor:pointer;
  }
}

