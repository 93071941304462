@media screen and (min-width: 768px) {
  .header-modal-container {
    svg {
      width: 24px;
      height: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }
  .close-button > svg {
    width: 24px;
    height: 24px;
  }

  .modal-form {
    background: var(--footer-background-color);
  }

  .modal-flex-container {
    display: flex;
    justify-content: space-evenly;
    gap: 18px;
    padding: 20px 60px;
    background: var(--feedback-background-color);
  }
  .modal-flex-container img {
    display: block;
    // width: 214px;
    height: 335px;
    margin: 0;
    border-radius: 4px;
  }

  .modal-block-container {
    background: transparent;
    .modal-form {
      background: transparent;
    }
  }
  .modal-social-icon {
    width: 20px;
    height: 20px;
  }
  .social-title-modal {
    font-size: 12px;
    color: var(--black-secondary-text-color);
  }

  .modal-submit-btn {
    width: 220px;
    margin: 0;
  }
  .order-form {
    padding: 0;
    gap: 24px;
    background: transparent;
  }
  .order-form > h2 {
    color: var(--black-secondary-text-color);
    font-size: 24px;
    margin: 0;
  }
  .order-form > h3 {
    margin: 0;
    font-size: 15px;
  }
  .input-modal-name,
  .input-modal-tel {
    font-size: 16px;
    color: var(--black-secondary-text-color);
  }

  .input-modal-name {
    margin-bottom: 20px;
  }
  .main-socials.modal-form {
    padding: 0;
    margin-top: 24px;
    background: var(--feedback-background-color);
    border-radius: 8px;
  }
  #wrightme {
    font-size: 15px;
    color: var(--black-secondary-text-color);
  }

  .modal-icons {
    margin-top: 12px;
    gap: 25px;
  }
  .main-social-icons li .social-icon-block > a {
    gap: 4px;
  }

  .btn-icon svg {
    width: 20px;
    height: 20px;
  }
  //thanks modal
  .thanks-section-container{
    width: 739px;
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  }
  .thanks-flex-container {
    display: flex;
    justify-content: center;
    padding: 20px 60px;
    gap: 25px;
    background: var(--header-white-color);

    .thanks-modal-img {
      display: block;
      border-radius: 4px;
      width: 214px;
      height: 290px;
      background: linear-gradient(
          0deg,
          rgba(159, 163, 170, 0.2) 0%,
          rgba(159, 163, 170, 0.2) 100%
        ),
        url('../images/thanks-modal-tab_2x.webp') lightgray 50% / cover
          no-repeat;
    }
    .thanks-main-modal {
      width: 381px;
      padding: 0;
      .thanks-main-modal-text {
        gap: 20px;
        margin: 40px 0;
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 1.4;
        }
      }
    }
    .thanks-main-modal-socials {
      padding: 12px 0px;
        h3{
          font-size: 15px;
          font-weight: 700;
          line-height: 1.4; 
          text-align: left;
        }
    }


  }
  .thanks-modal-btn{
    padding: 20px 16px;
 
  }

  .social-icon-block a{
      display:flex;
      flex-direction: row;
      gap:4px;
  }
  .thanks-btn-submit{
    margin:0;
    border: 3px solid var(--bronze-button);
    color: var(--black-button-color);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 14px 60px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    display: flex;
    .btn-icon{
      display:block;
  }
  }
  .thanks-btn-submit:hover{
    background: var(--button-hover-gradient);
    color:var(--header-white-color);
    cursor: pointer;
    svg{
      fill:var(--header-white-color);
    }
  }

}
