@media screen and (min-width: 1280px) {
  // .feedback-section,
  .feedback-slider-section {
    background: var(--feedback-background-color);
  }
  .background-container {
    background: var(--feedback-background-color);
  }

  .feedback-cases-container{
    padding: 100px 88px;
    margin: 0 auto;
    max-width: 1280px;
    background: transparent;
    h2{
        font-size: 32px;
        margin-bottom: 34px;
        font-family: 'Merriweather';
        font-size: 32px;
        font-weight: 700;
        line-height: 1.4; 
    }
    .feedback-img-cases {
        background: #d3d3d3 url('../images/feedback_cases_pc_2x_h418.webp') 50% / cover no-repeat;
        border-radius: 4px;
        width: 352px;
        height: 418px;
    }
  }
  .feedback-container {
    padding: 100px 88px;
    max-width: 1280px;
    margin: 0 auto !important;
    background: transparent;
    .feedback-img {
      background: url('../images/feedback_2x_pc_h418.webp');
      height: 418px;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 34px;
    }
    .feedback-img-main {
      height: 418px;
      border-radius: 4px;
      background: url('../images/feedback_2x_h265.webp');
      background-size: cover;
      background-position: center;
    }
  }
  .cases-feedback-slider{
    margin-top: 0;
  }


  .slick-slide {
    margin-left: 10px; 
  } 


  .slider-slide {
    padding: 20px 20px;
    height: 416px;
    width: 352px !important;

    h3 {
      font-size: 24px;
    }
  }
  .stars-mob {
    display: none;
  }

  .stars {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .stars > li > svg {
    width: 20px;
    height: 20px;
  }
  .quotes svg {
    width: 8px;
    height: 14px;
  }

  .slider-text {
    font-size: 16px;
  }

  .feedback-flex-container {
    display: flex;
    gap: 24px;
    .width-container{
        width: 728px;
    }

    .feedback-slider {
      margin-top: 0;
   
    }
  }

  .cases-prev-slide svg,
.cases-next-slide svg, 
.main-prev-slide svg,
.main-next-slide svg {
    width: 48px;
    height: 48px;
}
.slick-arrow :hover{
  cursor: pointer;
}


}
