@media screen and (min-width: 768px) {

  .feedback-container,
.feedback-cases-container {
    padding: 70px 60px;
    h2{
        font-size: 24px;
    }
    img{
        display:none;
    }
.feedback-img{
    background-image: url('../images/feedback_2x_h265.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 265px;
    border-radius: 4px;
}
}

.slider-wrapper .slick-slide {
    margin: 0;
    padding: 0;
 
}


.slider-wrapper .slick-slide {
    margin-right:0px;  /* Устанавливаем расстояние между слайдами */
    margin-left: 10px;
    padding: 0;

}
.slider-slide {
  height: 280px;
    width: 312px !important;
    margin-left: -20px;
}
.cases-prev-slide svg,
.cases-next-slide svg, 
.main-prev-slide svg,
.main-next-slide svg {
    width: 40px;
    height: 40px;
}
.stars-mob{
margin-top: 8px;
margin-bottom: 20px;
}
.stars-mob > li > svg {
    width: 14px;
    height: 14px;
  }
  .quotes{
    margin-bottom: 8px;
  }
  .quotes-bottom{
    margin-top: 8px;
  }
}


