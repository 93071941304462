// feedback-section
.feedback-slider-section{
  background: var(--feedback-background-color);
}

.feedback-container,
.feedback-cases-container {
  padding: 40px 15px;
  // background: var(--feedback-background-color);

  .feedback-img-cases {
    background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url('../images/feedback_2x_mob_h265.webp') 50% / cover no-repeat;
    border-radius: 4px;
    height: 187px;
  }
}

.feedback-img{
  background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url('../images/feedback_2x_mob_h265.webp') 50% / cover no-repeat;
  border-radius: 4px;
  height: 187px;
}
.feedback-container,
.feedback-cases-container {
  h2{
  color: #000;
  font-family: 'Merriweather';
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 24px;
  }
}
// .feedback-container img {
//   border-radius: 4px;
//   width: 100%;
// }

// slider
.slider-slide {
  // width: 250px !important;
  border-radius: 4px;
  border: 1px solid var(--border-grey);
  padding: 10px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 1)
    ),
    url('../images/mob-menu-w320.webp');
    background-size: cover; 
    background-repeat: no-repeat; 
  background-color: lightgray;
  background-position: 50%;
  height: 260px;
  margin-left:10px;
  
}
.slider-slide h3 {
  color: #000;
  font-family: 'Merriweather';
  font-size: 11px;
  line-height: 1.4;
}

.stars{
  display:none;
}

.stars-mob {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 12px;
}
.stars-mob > li > svg {
  width: 10px;
  height: 10px;
}
.quotes {
  display: flex;
  margin-bottom: 4px;
}
.quotes svg {
  width: 5px;
  height: 7px;
}
.slider-text {
  color: #000;
  font-family: 'Roboto';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; 
}
.quotes-bottom {
  justify-content: flex-end;
}

.cases-feedback-slider,
.main-feedback-slider {
  margin-top: 12px;
}


.slick-slide {
  margin-left: 20px; 
}


.slider-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.cases-prev-slide,
.cases-next-slide,
.main-prev-slide,
.main-next-slide {
  border: none;
  background: transparent;
}

.cases-prev-slide svg,
.cases-next-slide svg, 
.main-prev-slide svg,
.main-next-slide svg {
  width: 32px;
  height: 32px;
}
