@media screen and (min-width: 1280px) {
.footer-container{
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 88px 10px;
}

.list-mobile_nav {
    gap:12px;
    display: flex;
    flex-direction: column;

}

.mobile-menu_link{
    font-size: 16px;
}
.logo-slogan{
    margin-top: 28px;
    font-size: 20px;
}
.s-icon{
    width: 20px;
height: 20px;
}
.all-rights{
    gap:50px;
    margin-left: 10px;
    h3{
        font-size: 15px;
    }
}


}