header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  opacity: 0.9;
}
.nav-links{
  display: none;
}
main{
  padding-top: 73px;
}
.header-container {
  border-bottom: 1px solid var(--header-white-color);
  background: var(--brown-gradient);
  padding: 14px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-link{
  color:transparent;
  text-decoration: none; 
}

.header-link a:hover {
  color: inherit; /* Возвращаем цвет ссылки при наведении на неё */
}
.name-logo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.igor {
  color: var(--header-white-color);
  font-family: 'Merriweather';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.header-logo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.line-left {
  width: 37px;
  height: 1px;
  color: var(--header-white-color);
}
.line-right {
  width: 45px;
  height: 1px;
  color: var(--header-white-color);
}
.label {
  color: var(--header-white-color);
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 2.88px;
}
.scale-icon {
  fill: var(--header-white-color);
}
.header-btn{
  display:none;
}

.mobile-menu-open {
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
}

.burger-menu svg {
  fill: var(--header-white-color);
  width: 24px;
  height: 24px;
}
.menu-img{
  display: none;
}
@media screen and (min-width: 1280px) {
  .mobile-menu {
    display: none;
  }
}
/* Кнопка бургер-меню */
@media screen and (max-width: 1279px) {
  .mobile-menu {
    position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  padding: 71px 36px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 1) 100%), url('../images/mob-menu-w320.webp') lightgray 50% / cover no-repeat;
  visibility: hidden;
  opacity: 0.9;
  pointer-events: none;
  transition: transform var(--transition-speed), visibility 500ms linear, opacity 500ms linear;
  transform: translateX(-100%);
    
    
  }
  .mobile-menu.is-open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(0);
  }
  // .mobile-menu-overlay{
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0,0.9); /* Установите нужный уровень прозрачности здесь */
  //   z-index: -1; /* Поместите псевдоэлемент под контент */
  // }
  
  .mobile-menu_close-btn {
    position: absolute;
    top: 22px;
    right: 15px;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .list-mobile_nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-menu_link {
    color: var(--header-white-color);
    font-family: 'Roboto';
    line-height: 1.4;
  }

}

.order-btn {
  padding: 14px 30px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--orange-button);
  color: var(--header-white-color);
}
