@media screen and (min-width: 768px) {
  .main-slogan-container {
    height: 481px;
    background-image: linear-gradient(
        0deg,
        rgba(2, 2, 2, 0.4) 1.01%,
        rgba(177, 170, 170, 0.4) 101.01%
      ),
      url('../images/tab-main-slogan.webp');
    background-size: cover, cover; /* Первое cover для градиента, второе для изображения */
    background-repeat: no-repeat, no-repeat; /* Оба слоя без повтора */
    background-position: center, center; /* Центрируем оба слоя */
    position: relative;
    margin-bottom: -5px;
  }
  .position-container {
    position: absolute;
    left: 60px;
    gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .hero-container {
    display: flex;
    gap: 24px;
    padding: 70px 60px;
    img {
      height: 429px;
      width: 312px;
      border-radius: 4px;
    }
  }
  .hero-name {
    margin-bottom: 24px;
    margin-top: 25px;
    h2 {
      font-size: 16px;
    }
    h1 {
      font-size: 20px;
    }
  }

  .slogan-text {

    h1 {
      font-size: 20px;
      text-align: left;
    }
    h3 {
      font-size: 16px;
      text-align: left;
      margin-bottom: 60px;
      width: auto;
    }
  }
  .slogan-order-btn {
    font-size: 16px;
    padding: 14px 40px;
  }
  .hero-details {
    display: block;
  }
  .tab-pc {
    display: block;
    p {
      font-size: 15px;
      font-weight: 400;
    }
  }
  .mob {
    display: none;
  }
  .slogan-order-btn:hover{
    cursor: pointer;
    background: var(--button-hover-gradient);
  }
.slogan-order-btn:active{
    background: var(--button-active-gradient);
    }

  // achievements-section
  .achievements-container {
    padding: 12px 60px;
    .achievements {
      flex-direction: row;
      justify-content: space-around;
    }
    #ach-line {
      transform: rotate(0deg);
    }
    .up-text {
      gap: 0px;
      font-family: 'Merriweather';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.4;
      .figures {
        font-family: 'Merriweather';
      }
      .bot-text {
        padding: 16px 0px;
        font-family: 'Merriweather';
      }
    }
  }
  // areas-section
  .areas-section-container {
    padding: 70px 60px;
    h1 {
      font-size: 24px;
    }
    .grid-container {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc((100% - 60px - 60px - 24px * 2) / 3), 1fr)
      );
      gap: 24px;
    }

    .main-card-image,
    .card-image {
      height: 136px;
    }
    .caption {
      padding: 12px 8px;
      display: flex;
      align-items: center;
      gap: 9px;
      height: 64px;
      img {
        display: block;
        width: 24px;
        height: 24px;
      }
      h3 {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  .slider-slide{
  
    height: 280px;
    width: 312px !important;
    h3{
        font-size: 14px;
    }
}
  .stars{
    margin-top: 8px;
    margin-bottom: 20px;
}

.stars > li > svg {
    width: 20px;
    height: 20px;
}
.quotes svg {
    width: 5px;
    height: 9px;
}

.slider-text {
    font-size: 12px;
}

  // order-section
  .order-section-container {
    padding: 70px 60px 50px 60px;
    img {
      height: 465px;
      // margin-bottom: 24px;
    }
  }
  .order-image{
    height: 621px;
    // width: 648px;
      background: linear-gradient(
          0deg,
          rgba(185, 185, 185, 0.10) 0%,
          rgba(185, 185, 185, 0.10) 100%
      ), 
      url('../images/igor-main-order-image_tab_2x.webp');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; 
  
  }
 
  .order-slogan,
 .order-main {
    padding: 24px 16px 0px 16px;
  }
//  .order-main {
//   background: transparent;
//  }
  .order-main>h2 {
      font-size: 18px;
      margin-bottom: 12px;
      font-family: 'Merriweather';
    }
    .order-main>h3 {
      font-size: 16px;
      margin-bottom: 24px;
      font-family: 'Roboto';
      font-weight: 400;
    }
    .form-label {
      font-size: 16px;
    }
    .btn-submit {
      margin-top: 34px;
      margin-bottom: 34px;
      margin-right: auto;
      margin-left: auto;
      width: 30vw;
      align-items: center;
    }
    .btn-submit:hover{
      background: var(--button-hover-gradient);
      color:var(--header-white-color);
      cursor: pointer;
      svg{
        fill:var(--header-white-color);
      }
    }
  
  .main-socials {
    padding: 0 16px 32px;
    h3 {
      font-size: 16px;
    }
    .social-container{
      .main-social-icons{
  
        width: 300px;
        justify-content: flex-start;
        gap: 80px;
      }
    }
  }
  .main-social-icons {
    margin-top: 20px;
    gap:15px;

    li {
      .social-icon-block > a {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }
  }

  .social-title {
    font-size: 15px;
  }
  .main-social-icon {
    width: 24px;
    height: 24px;
  }
  .contacts-order-section{
    display:block;
  }
  .main-order-section-mob-contacts{
    display:none;
  }
  //adress-section
  .adress-section-container {
    padding: 0px 0px 10px 0px;
    h2 {
      font-size: 24px;
      margin-bottom: 26px;
      color: var(--black-button-color);
    }
    .adress p,
    .tel-number a,
    .mail a {
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .adress {
      margin-bottom: 12px;
    }
    .adress svg,
    .tel-number svg,
    .mail svg {
      margin-right: 8px;
      height: 20px;
      width: 20px;
    }
    .map {
      width: 100%;
      height: 467px;
    }
  }
//contacts
.contacts-container{
  height: 485px;
  background: linear-gradient(0deg, #dcdad84d 0% 100%), #d3d3d3 url('../images/tab-menu-2x.webp') 50% / cover no-repeat;
  display: flex;
  justify-content: flex-start;
}
.contact-slogan-text{
  width: 315px;
  display: flex;
  align-items: center;
  color:var(--black-secondary-text-color);
  margin-top: 107.13px;
  margin-bottom: 60px;
  h1{
    text-align: center;
    margin-bottom: 28px;
    width: 648px;
      }
      h3{
        text-align: center;
        margin-bottom: auto;
      }
}
.contact-slogan-btn{
  padding: 14px 40px;
  width: 263px;
  margin: 0 auto;
  background: transparent;
  color:var(--black-secondary-text-color);
}
.contact-slogan-btn:hover{
  background: var(--button-hover-gradient);
  color:var(--header-white-color);
  cursor: pointer;
}


}
