@media screen and (min-width: 1280px) {
  .service-types-pc-gradient-container {
    background: var(--main-order-gradient);
  }
  .service-types-section-container {
    padding: 100px 88px 0px;
    max-width: 1280px;
    margin: 0 auto;
    background: transparent;
    h2 {
      font-size: 32px;
    }
    p {
      margin-bottom: 0;
      font-size: 24px;
      width: 851px;
    }
  }

  .migration-serv-container {
    padding: 0px 88px 100px;
    max-width: 1280px;
    margin: 0 auto;
    background: transparent;
  }

  .service-img-flex-container {
    display: flex;
    flex-direction: row;
    margin-top: 34px;

    gap: 24px;
    .service-title {
      width: 728px;
      justify-content: space-between;

      .service-title-flex-container {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        img {
          width: 80px;
          height: 80px;
        }
        h1 {
          font-family: 'Merriweather';
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
      }
      .services-btn {
        margin: 0 auto 0 0;
        font-size: 20px;
        width: auto;
      }
    }
  }
  .services-btn:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }
  .services-btn:active {
    background: var(--button-active-gradient);
    color: var(--header-white-color);
  }

  .migration-serv-container article {
    color: var(--black-secondary-text-color);
    margin: 0;
    p.migtation-descr {
      font-size: 20px;
      margin-top: 44px;
      margin-bottom: 44px;
    }
    p.tax-descr {
      font-size: 20px;
      margin-top: 58px;
      margin-bottom: 58px;
    }
    p.criminal-descr {
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 0px;
      .criminal-descr-first-line {
        display: block;
        margin-bottom: 10px;
      }
    }
    p.fintech-descr {
      font-size: 20px;
    }
    p.military-descr {
      font-size: 20px;
      .military-descr-first-line {
        display: block;
        margin-bottom: 10px;
      }
    }
    p.civil-descr {
      font-size: 20px;
    }
    p.admin-descr {
      font-size: 20px;
    }
    p.intellectual-descr {
      font-size: 20px;
      .intellectual-descr-first-line {
        display: block;
        margin-bottom: 10px;
      }
    }
    p.debt-rec-descr {
      font-size: 20px;
    }
    p.deb-pro-descr {
      font-size: 20px;
    }
    p.labor-descr {
      font-size: 20px;
    }
    p.business-descr {
      font-size: 20px;
    }
  }

  .side-buttons {
    display: none;
  }
  .criminal-img,
  .fintech-img,
  .tax-img,
  .civil-img,
  .admin-img,
  .intellectual-img,
  .military-img,
  .labor-img,
  .debt-recovery-img,
  .debtor-protection-img,
  .business-law-img,
  .migration-img {
    display: none;
  }

  .service-types-img-pc {
    display: block;
    border-radius: 4px;
    width: 352px;
    height: 400px;
  }
  .migration-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/migration-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .criminal-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/criminal-law-pc_2x.webp') lightgray 50% / cover no-repeat;
  }
  .tax-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/tax-image_2x.webp') lightgray 50% / cover no-repeat;
  }
  .fintech-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/fintech-image_2x.webp') lightgray 50% / cover no-repeat;
  }
  .civil-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/civil-image_2x.webp') lightgray 50% / cover no-repeat;
  }
  .admin-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/admin-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .intellectual-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/intellectual-image_2x.webp') lightgray 50% / cover
        no-repeat;
  }

  .military-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/military-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .debt-rec-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/debt-rec-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .deb-pro-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/deb-pro-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .labor-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/labor-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .business-img-pc {
    background: linear-gradient(
        0deg,
        rgba(135, 125, 125, 0.4) 0%,
        rgba(135, 125, 125, 0.4) 100%
      ),
      url('../images/business-image_2x.webp') lightgray 50% / cover no-repeat;
  }

  .intel-list {
    margin-bottom: 24px;
    h2 {
      font-size: 20px;
      font-family: 'Roboto';
    }
    .intel-title li {
      font-size: 16px;
    }
  }

  .business-whyme-title,
  .labor-whyme-title,
  .admin-pc-whyme-title,
  .debt-rec-pc-whyme-title {
    width: 728px;
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: var(--black-secondary-text-color);
      font-family: 'Roboto';
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
  .side-buttons-pc-flex-container {
    display: block;
  }
  .service-type-pc-flex-container {
    display: flex;
    gap: 24px;
  }

  .labor-pc-service-title-container,
  .admin-pc-service-title-container,
  .debt-rec-pc-service-title-container {
    display: flex;
    flex-direction: column;
    .pc-column-service-items {
      margin-bottom: -18px;
      .service-item {
        p {
          font-size: 16px;
        }
        .service-details {
          h3 {
            width: 696px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .military-service-details {
    align-items: flex-start;
    margin-bottom: 0;
  }

  .pc-service-title-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    .pc-column-service-items {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 410px;
      // как перебить  max-width: 410px;?
      .service-item {
        margin: 0;
        min-height: 120px;
        //либо сюда добавить width
        .service-details {
          h3 {
            width: 320px;
            font-size: 16px;
            line-height: 140%;
          }
        }
      }
    }
  }

  .military-service-item {
    min-height: 88px !important;
  }
  .deb-pro-service-details {
    align-items: flex-start;
    margin-bottom: 0;
  }
  .deb-pro-service-item {
    min-height: 38px !important;
  }

  .side-service-buttons-container {
    .service-buttons li {
      padding: 20px 10px;
      width: 352px;
      &.select {
        background: var(--select-bg-color);
        a {
          text-decoration: underline;
        }
      }
    }
  }

  .service-buttons li a {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .btn-title {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    gap: 8px;
    img {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0;
    }
    h3 {
      font-size: 20px;
    }
  }

  .debt-rec-pc-whyme-title,
  .whyme-title {
    h2 {
      font-size: 20px;
      font-family: 'Roboto';
      color: var(--black-secondary-text-color);
    }
    p {
      font-size: 16px;
    }
  }
  .main-order-section {
    display: none;
  }
  .modal-order-window {
    display: block;
  }
  .main-order-section-pc {
    display: block;
    background: var(--select-bg-color);
  }
  .section-pc-container {
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
    display: flex;
  }
  .service-item-bottom-text {
    font-size: 16px;
  }

  .labor-bottom-text,
  .admin-bottom-text,
  .debt-rec-pc-bottom-text {
    margin-top: 18px;
  }
}
