@media screen and (min-width: 768px) {
// cards
.cases-content{
    position: relative;
}

.case-description-container {
    position: static;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(0);
  }


  .case-description-container.is-hidden {
    display: none;
  }
  .case-description-container.right-aligned {
    transform: translateX(-224px);
  }

.case-card-pc-module:not(:last-child){
    margin-bottom: 18px;
}
.card-flex-pc-container{
    display: flex;
    gap: 24px;

}
.card-flex-pc-container:not(:last-child){
    margin-bottom: 18px;
}
.wide-image{
    max-width: 424px;
  }

.narrow-image{
       height: 100%;
  }
.height-img{
    height: 142px;
}
.caption{
   background:var(--caption-grey);
}
.case-caption {
    font-size: 13px;
    background: var(--light-yellow);

}
.euro-wide{
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/euro-wide-tab_2x.webp') lightgray 50% /cover no-repeat;
    border-radius: 4px 4px 0px 0px;
    height: 100%;
  }

.euro-narrow{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/euro-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}

.bispart-narrow{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/bispart-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}
.bispart-wide{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/bispart-wideXXX-tab_2x.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}

.cuttingmarriage-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/cuttingmarriage-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}
.cuttingmarriage-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/cuttingmarriage-wide-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.justice-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/justice-wide-tab_2x.webp') lightgray 50% / cover no-repeat;
}
.justice-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/justice-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.criminal-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/criminal-wide-tab_2x.webp') lightgray 50% / cover no-repeat;
}
.criminal-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/criminal-wide-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.alimenty-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/alimenty-wideXXX-tab_2x.webp') lightgray 50% / cover no-repeat;
}
.alimenty-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/alimenty-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.land-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/land-wideXXX-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.land-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/land-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.bayer-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/bayer-wideXXX-tab_2x.webp') lightgray 50% / cover no-repeat;
}
.bayer-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/bayer-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.custom-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/custom-wideXXX-tab_2x.webp') lightgray 50% / cover no-repeat;
}

.custom-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/tab/custom-narrow-tab_2x.webp') lightgray 50% / cover no-repeat;
}


    // long description
.case-card{
margin-top:18px;
}
.cases{
    padding: 70px 60px;
}
.cases-about{
    h1{
        font-size: 24px;
    }
    p{
        font-size: 15px;
        width: 509px;
    }
}
.cases-container{
    display:none;
}

.cases-pc-container{
    display:flex;
    flex-direction: column;
}

.case-card{
    display:flex;
    flex-direction: row;
    gap:24px;

}
.case-flex-container-left{
    max-width: 55vw;
}
.case-flex-container-right{
    max-width: 26vw;
}

.case-description{
        margin-top: 0;
    h2{
        font-size: 18px;
    }
.adm-law{
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
}
h3{
    font-size: 16px;
}
p{
    font-size: 15px;
}
.resolution{
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
}
ol{
    padding-left: 15px;
}
ol>li{
    font-size: 15px;
}

}

.case-flex-container-right{
    ul{
        padding-left: 15px;
    }
    ul>li{
        font-size: 15px;

    }
}


.main-idea{
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 1.4;
}
.cases-btn{
    width: 200px;
    padding-top:14px;
    padding-bottom: 14px;
    padding-left: 0;
    padding-right: 0;
}


}