@media screen and (min-width: 768px) {
  .service-types-section-container {
    padding: 70px 60px 34px 60px;
    h2{
      font-size: 20px;
    }
  }
  .select-container{
    display: none;
  }
  .btn-title img,
  .side-buttons-pc-flex-container{
    display:none;
}
.btn-title img
  .migration-serv-container {
    padding: 0px 60px 70px 60px;
  }
  .main-order-section-pc{
    display:none;
}
  .side-buttons {
    display: block;
  }
  .service-flex-container {
    display: flex;
    gap: 24px;
    margin-bottom: 34px;
  }
  .service-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      padding: 12px 10px;
      background: var(--light-yellow);
      color: var(--black-secondary-text-color);
      font-family: 'Merriweather';
      font-weight: 700;
      line-height: 1.4;
      border-radius: 4px;
      width: 230px;
      a{ 
        color: var(--black-secondary-text-color);
        }
    }
  }
  .service-buttons li.select {
    background: var(--select-bg-color);
}

  .intel-list h2{
    font-size: 18px;
  }
  .labor-law-items li,
  .debt-recovery-items li,
  .intel-title li{
      font-size: 15px;
 
  }

  .service-title {
    align-self: auto; /* Сброс значения align-self */
  }
  .service-title-flex-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 50px;
      height: 50px;
      margin: 0;
    }
    h1 {
      font-size: 24px;
      color: var(--black-secondary-text-color);
      margin: 0;
    }
  }
  .migration-img {
    height: 300px;
  }
  .migration-serv-container article {
    margin-top: 14px;
  }
  .migration-serv-container article p {
    font-size: 15px;
    margin-bottom: 18px;
  }
  .services-btn {
     font-size: 16px;
    color:var(--black-button-color);
    margin:0;
    width: 263px;
    margin-left: auto;
    padding: 14px;
  }
.whyme-title{
    h2{
        font-size: 18px;
    }
    p{
        font-size: 15px;
    }
}
.service-details{
    h3{
        font-size: 15px;
        color:var(--black-secondary-text-color);
    }
}
.service-item p {
    font-size: 15px;
    color:var(--black-secondary-text-color);
}

.order-form-gradient-container-2{
    background: var(--select-bg-color);
}

.page-form-hidden{
    h2,h3{
        display:none;
    }
    .page-form-2{
        h2,h3{
            display:block;
            color:var(--black-secondary-text-color);
        }
        h2{
            font-size: 24px;
            font-weight: 700;
        }
        h3{
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 20px;
        }
    }

}

.order-img{
    height:303px;
}

}
