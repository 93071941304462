@media screen and (min-width: 1280px) {
  .cases {
    padding: 0;
    .cases-content {
      background: none;
      max-width: 1280px;
      margin: 0 auto;
      padding: 100px 88px;
    }
  }

  .steps-section {
    background: var(--select-bg-color);
    .steps-section-container {
      background: none;
      max-width: 1280px;
      margin: 0 auto;
      padding: 100px 88px;
    }
  }

  .cases-about {
    margin-bottom: 34px;
    color: var(--black-secondary-text-color);
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
      width: 704px;
    }
  }
  .case-card-pc-module:not(:last-child) {
    margin-bottom: 24px;
  }
  .card-flex-pc-container:not(:last-child) {
    margin-bottom: 24px;
  }
  .height-img {
    height: 236px;
    overflow: hidden;
  }
  .card-image,
  .main-card-image  {
    transition: transform var(--transition-speed); /* Для плавного перехода */
    }

    .main-card-image:hover,
    .card-image:hover{
      transform: scale(1.15);
   }
  .wide-image {
    max-width: 712px;
  }
  .card-image-pc-container {
    cursor: pointer;
    transition: all var(--transition-speed);
  }
 .narrow-image {
    width: 0;
  }

  .wide-image:hover,
  .narrow-image:hover {
    cursor: pointer;
  }

  .caption {
    padding: 8px;
    height: 84px;
  }
  .case-caption {
    font-size: 20px;
  }
//case animations
.card-flex-pc-container {
  position: relative; /* Добавлено для обеспечения правильного позиционирования */
}
.case-description-container {
  width: 1104px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateX(0);


  .case-card{

    .case-flex-container-left {
      width: 728px;
      .case-description {
        h2{
          font-size: 24px;
        }
        h3{
          font-size: 20px;
        }
        p{
          font-size: 16px;
        }
        ol>li{
          font-size: 16px;
        }
      }
    }
    .case-flex-container-right {
      width: 352px;
      h3{
        font-size: 20px;
      }
      ul>li{
        font-size: 16px;
      }
      p{
        font-size: 16px;
      }
    }

  }
  .cases-btn {
    width: 352px;
  }

}

//добавить изображения широких-узких карточек

  //steps
  .four-steps {
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
      width: 703px;
    }
  }
  .steps-container {
    .first-step {
      padding: 8px 4px;
      .step {
        width: 60px;
        height: 60px;
        font-size: 20px;
        margin-bottom: 24px;
      }
      h3 {
        font-size: 20px;
        margin-bottom: 12px;
      }
      .step-details li {
        font-size: 20px;
      }
    }
  }


}
