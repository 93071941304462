@media screen and (min-width: 1280px) {
    main{
        padding-top: 115px;
    }
    .main-slogan {
        height: calc(100vw * 0.3695);
        min-height:729px;
        background-image: linear-gradient(
            0deg,
            rgba(2, 2, 2, 0.4) 1.01%,
            rgba(177, 170, 170, 0.4) 101.01%
          ),
          url('../images/pc-main-slogan.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .main-slogan-container {
        background-image: none;
        max-width: 1280px;
        margin: 0 auto;
        display: block;
      }

    .position-container {
        top: calc(100vw * 0.1061);
    }



.slogan-text{
    width: 540px;


h1{
    font-size: 32px;
    margin-bottom: 28px;
    width: 540px;
}
h3{
    font-size: 24px;
    width: 479px;
}
}


.contact-slogan-text{
    align-items: center;
    margin-top:  calc(100vw * 0.1057);
     
}

.contact-slogan-btn {
    width: 308px;
    font-size: 20px;
    padding: 14px;
}

.main-slogan-text{
    align-items: flex-start;
}




.order-btn {
    padding: 14px ;
    font-size: 20px;
    width: 308px;
  }

  //hero section
  .hero-section{
    background: var(--brown-gradient);
  }
  .hero-pos-container{
    max-width: 1280px;
    margin: 0 auto;
  }
.hero-container{
    padding:100px 88px;
    align-items: center;
    justify-content: space-between;
    background: transparent;

    .hero-details{
        width: 541px;
    }
}
.hero-container img{
    width: 446px;
    height: 612px;
    border-radius: 4px;
}

.hero-name{
    margin-top: 0;
    margin-bottom: 84px;
    h2{
        font-family: 'Roboto';
        font-size: 24px;
        font-weight: 400;
        line-height: 140%;
    }
    h1{
        font-size: 32px;
    }
}
.tab-pc{
    margin-top: 0;
    p{
        font-size: 20px;
    }
}
.achievements-section{
    background: var(--achievements-background-color);
}
.achievements-container{
    padding: 24px 45px;
    max-width: 1280px;
    margin: 0 auto;
    background: transparent;
}
.achievements{
    justify-content: space-evenly;
}
.up-text{
    font-size: 24px;
}
.areas-section{
    background: var(--brown-gradient);
}

.areas-section-container{
    padding: 100px 88px;
    max-width: 1280px;
    margin: 0 auto;
    background: transparent;
    h1{
        font-size: 32px;
        margin-bottom: 34px;
    }

}
.grid-container{
    grid-template-columns: repeat(auto-fit, minmax(calc((100% - 176px - (24px * 4)) / 3), 1fr));
    gap: 24px;
}

.areas-section-container .main-card-image{
    height: 250px;
}
.areas-section-container .card-image{
    height: 250px;

}
.areas-section-container .caption{
    padding: 20px 8px;
    height: 70px;
    img{
        width: 30px;
height: 30px;
    }
    h3{
        font-size: 20px;
    }
}

.product-card:hover .card-image {
    transform: scale(1.10);
    // transform-origin: bottom ;
    // overflow: hidden;
}



//contacts
.contacts-address-section{
    background: var(--feedback-background-color);
}
.contacts-main-slogan{
    background-image: linear-gradient(#b1aaaa66 -1.01%, #02020266 98.99%), url('../images/pc-menu-2x.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vw * 0.3695);
    min-height: 742px;
}
.contacts-container {
    max-width: 1280px;
    margin:0 auto;
    background: transparent;
}

.contact-slogan-text{
    margin-bottom: 80px;
    h1{
        font-size: 32px;
        margin-bottom: 28px;
    }
    h3{
        font-size: 24px;
    }
}

.order-address-flex-container{
    display:flex;
    flex-direction: row-reverse;
    max-width: 1280px;
    margin: 0 auto;
    padding:100px 0px;
    gap: 24px;
    justify-content: space-around;
    padding-right: 88px;

.adress-section-container{
    width: 749px;
    background: transparent;
    padding: 0;
    h2{
        font-size: 32px;
        margin-bottom: 34px;
    }
    .tel-number a{
        font-size: 20px;

        svg{
            height: 20px;
            width: 20px;
        }
    }

.mail a{
    font-size: 20px;
}
.mail svg{
    width: 22px;
height: 17px;
}
.adress{
    margin-bottom: 20px;
}
.adress svg{
    width: 22px;
    height: 24px;
}
.adress p{
    font-size: 20px;
}
    .map{
      height: 805px;
    }
}
.page-order-section{
    display: block;
}
.order-image{
    height: 391px;
    width: 419px; 
    background: linear-gradient(
        0deg,
        rgba(185, 185, 185, 0.10) 0%,
        rgba(185, 185, 185, 0.10) 100%
    ), 
    url('../images/igor-main-order-image_pc_2x.webp') no-repeat center;
    background-size: cover; 
    border-radius: 4px;
  }


.order-section-container{
    background: transparent;
    padding:0;
    img{
        height: 391px;
    }
    .order-main{
        padding: 48px 16px 0;
        width: 419px;
    }
    .order-main > h2{
        font-size: 24px;
    }
    .order-main > h3{
        font-size: 20px;
        width: 387px;
    }

}
.form-label {
    font-size: 20px;
}
.form-input {
    font-size: 16px;
}
.btn-submit {
    width: 247px;
    font-size: 20px;
    align-items:center;
    .btn-icon{
        width: 20px;
        height: 20px;
        margin-top: 2px;
 
    }
}
.main-socials {
    padding: 8px 16px 32px;
    width: 419px;
    .social-container{
        flex-direction: column;
    }
    h3{
        font-size: 20px; 
    }
    .main-social-icons {
        // padding: 0 24px;
        justify-content: space-between;
        width: 339px;
    }

}

.gap-pc-up{
 gap: 120px;
}
.gap-pc-low{
    gap: 80px;
}
.main-social-icon {
    width: 30px;
    height: 30px;
}
.main-social-icons li .social-icon-block > a {
    gap: 8px;
}
.social-title {
    font-size: 20px;
}

}
}