@media screen and (min-width: 1280px) {
    .mystory-section-pc{
        display:block;
        background: var(--feedback-background-color);

    }
.mystory-section{
    display:none;
}
.mystory-container{
    padding: 100px 88px;
    max-width: 1280px;
    margin:0 auto;
    background: transparent;
   .hero-name {
    margin-bottom: 34px;
        h3{
            font-size: 20px;
            font-weight: 500;
        }
   }
}

#flex-text {
    max-width: 634px;
}
.mystory-section-text p{
    font-size: 20px;
    font-weight: 400;
 
}
.mystory-section-text p:not(:last-child) {
    margin-bottom: 18.5px;
  }
  
  .mystory-section-text p:last-child {
     margin-bottom: 0px; 
  }
.aboutme-flex-text-container{
    display:flex;
    gap: 24px;
}

.aboutme-text-container{
display:block;
width: 634px;
}

.aboutme-img {
    width: 446px;
    height: 672px;
    background: #d3d3d3 url('../images/mystory-pc_2x.webp') center / cover no-repeat;
}
#mystory-slogan-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 34px;
    margin-bottom: 34px;
}
.order-btn-aboutme {
    margin: 0 auto 0 0 ;
}
//why me
.whyme-section{
    background: var(--select-bg-color);
}
.whyme-section-container{
    padding: 60px 88px;
    max-width: 1280px;
    margin:0 auto;
    background: transparent;
    h1{
        font-size: 32px;
    margin-bottom: 34px;
    }
}
.whyme-flex-container {
    gap: 32px;
}
.online-support{

    img{
        width: 60px;
    height: 60px;
    margin-bottom: 24px;
    }
    h4{
        font-size: 20px;
    margin-bottom: 12px;
    }
    p{
        font-size: 20px;
    }
}
//main-order-section-message-pc
.order-form-display-container{
    display:none;
}
.main-order-section-message-pc{
    display:block;
    background: var(--feedback-background-color);
}
.section-message-pc-container{
    padding: 100px 88px;
    max-width: 1280px;
    margin:0 auto;
    display: flex;
    gap:24px;
}
.message-pc-block-container{
    display:block;
    .order-igor-tab-img{
        width: 447px;
        height: 402px;
        margin: 0 auto;
        border-radius: 4px;
        background: linear-gradient(0deg, rgba(159, 163, 170, 0.10) 0%, rgba(159, 163, 170, 0.10) 100%), url('../images/order-igor-pc_2x.webp') lightgray 50% / cover no-repeat;
    }
}

.page-form-socials{
    margin-top: 32px;
}
.main-socials h3 {
    color: var(--black-secondary-text-color);
     font-size: 20px;
    font-weight: 500;
    line-height: 140%;
}
.main-social-icon{
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}
.page-form-social-title {
        font-size: 20px;
          font-weight: 400;
      }

.form-container{
    padding: 24px 16px;
    .page-form-title{
        color: var(--black-secondary-text-color);
        h2{
            font-family: 'Merriweather';
            font-size: 24px;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 12px;
        }
        h3{
            font-size: 20px;
            font-weight: 400;
            line-height: 140%;
        }
    }
}
.feedback-form{
    margin-top: 40px;
    .form-order-form {
        width: auto;
    }
}
.page-form-label{
    font-size: 20px;
    font-weight: 500;
}
.input-name,
.input-tel,
.input-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}
.fixed-height{
    overflow: hidden; 
    resize: none; 
}
.page-form-btn {
    margin: 34px auto 0;
    color: var(--black-button-color);
    font-size: 20px;
    width: 247px;
}






}