.mystory-section-pc{
    display:none;
}

.main-order-section-message-pc{
    display:none;
}
.mystory-container{
    padding: 50px 16px;
    background: var(--feedback-background-color);
}

.mystory-name{
    color:var(--black-main-text-color);
}
.mystory-name h2{
    font-size: 13px;
    margin-bottom: 8px;
}
.mystory-name h1{
    margin-bottom: 8px;
}
.mystory-name h3{
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
}

.mystory-container img{
    // height: 288px;
    border-radius: 4px;
    width: 100%;
}
.aboutme-img {
    display:none;
}
.mystory-section-text{
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
}

#flex-text{
    margin-top: 24px;
}
#block-text{
    margin-bottom: 24px;
}
#mystory-slogan-text{
    display:none;
    
}

.order-btn-aboutme{
    color:var(--black-button-color);
border: 1px solid var(--bronze-button);
margin: 0 auto; /* Устанавливает отступы слева и справа автоматически, выравнивая элемент по центру по горизонтали */
display: block;
}

//whyme-section
.whyme-section-container{
    background-color: #FFFFFF;
    padding: 50px 16px;
    gap: 4px;
}
.whyme-section-container h1{
    color: #000;
    font-family: 'Merriweather';
    font-size: 16px;
  margin-bottom: 24px;
    font-weight: 700;
    line-height: 140%;
}
.online-support{
display:flex;
flex-direction: column;
align-items: center;
text-align: center;
color: #000;
font-family: 'Roboto';
font-size: 12px;
padding: 8px;


}
.online-support img{
width: 30px;
height: 30px;
margin-bottom: 8px;
}

.online-support h4{
    font-weight: 700;
    margin-bottom: 4px;
}

.online-support p{
    font-weight: 400;
    text-align: center;
}