.order-form-container{
    padding: 0px 0px 30px 0px;
    background: var(--header-white-color);
}

.error-message {
    color:brown;
    font-weight: bold;
    margin-top: 5px;
  }
// .page-order-section,
  .order-igor-tab-img{
    display:none;
}
