.thanks-header-modal,
.header-modal{
background: var(--brown-gradient);
display:flex;
padding: 16px;
align-items: center;
justify-content: space-between;
}

.header-modal-container{
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    svg {
        width: 18px;
        height: 18px;
      }
}
.header-modal-container h2{
    color:var(--modal-white-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
}
.close-button{
    background-color: transparent;
    border: none;
    svg {
        width: 18px;
        height: 18px;
      }
}

.modal-flex-container img{
    display:none;
}
.modal-form{
    background: var(--feedback-background-color);
}
#padding-container{
    padding-bottom: 0px;
}
.modal-form h2{
    text-align: center;
}

.modal-form h3{
text-align: center;
margin-top:12px;

}
.input-modal-name{
margin-bottom:12px;
background-color: transparent;
}
.input-modal-tel{
    background-color: transparent;
    margin-bottom: 0px;
    height: 38px;
}
#wrightme{
    text-align: left;
    margin-top: 0;
}

.modal-order-btn,
.thanks-modal-btn{
    background: var(--feedback-background-color);
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}
.modal-social-icon{
    width: 20px;
height: 20px;
}
.btn-submit{
    background-color: transparent;
}

.btn-icon svg{
    width: 16px;
height: 16px;
}

// скрыли секцию "спасибо"
// .thanks-is-hidden{
//     display: none;
// }

.thanks-main-modal{
    padding:20px 0px;
    h1{
        color: var(--black-secondary-text-color);
        text-align: center;
        font-family: 'Merriweather';
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
    }
    .thanks-main-modal-text{
        margin:20px;
        display: flex;
        text-align: center;
        flex-direction: column;
        gap: 8px;
    }
    .thanks-main-modal-socials{
        padding: 8px 16px;
        h3{
            text-align: center;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 700;
            line-height: 140%;
        }
        ul{
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }

}
.thanks-backdrop,
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0; /* заменить на opacity: 0;Изначально модальное окно скрыто */
    box-shadow: var(--card-shadow);
    transition: opacity var(--transition-speed);
    z-index: 9999;
    pointer-events: none; /* Отключаем взаимодействие с содержимым страницы */
  }
  
.thanks-backdrop.is-visible,
  .backdrop.is-visible {
    opacity: 1; /* Делаем модальное окно видимым при добавлении класса .is-visible */
    pointer-events: auto; /* Включаем взаимодействие с модальным окном */
  }
  //thanks modal
  .thanks-section-container{
   width: 300px;
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  }

  .thanks-flex-container {
    display: flex;
    justify-content: center;
    padding: 20px 16px;

    background: var(--header-white-color);

    .thanks-modal-img {
display: none;
    }
    .thanks-main-modal {
      padding: 0;
      .thanks-main-modal-text {
        margin: 20px 0px;
        gap: 9px;
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.4;
        }
      }
    }
    .thanks-main-modal-socials {
      padding: 8px 0px;
        h3{
          font-size: 14px;
          font-weight: 700;
          line-height: 1.4; 
          text-align: center;
        }
    }
  }

  .thanks-modal-btn{
    padding: 20px 16px;
   }

   .thanks-btn-submit{
    width: 268px;
    margin:0;
    border: 3px solid var(--bronze-button);
    color: var(--black-button-color);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 14px 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    display: flex;
    .btn-icon{
        display:none;
    }
  }

  .thanks-btn-submit:hover{
    background: var(--button-hover-gradient);
    color:var(--header-white-color);
    cursor: pointer;

  }