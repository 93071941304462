@media screen and (min-width: 1280px) {
.mobile-menu-open{
    display:none;
}
header{
    background: var(--brown-gradient);
    border-bottom: 1px solid var(--header-white-color);
}
.header-pos-container{
    max-width: 1280px;
    margin: 0 auto;
}



.header-container{
    padding: 24px 88px;
    background: transparent;
    border-bottom:none;
}
.igor{
       font-size: 32px;
       font-weight: 700;
       letter-spacing: 0.64px;
}

.line-left{
    width: 110px;
}
.label{
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 3.84px;
}
.line-right{
    width: 59px;
}

.nav-links{
    display: flex;
    gap:8px;
    li{
       padding:4px;
        font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        a{
            color:var(--caption-grey);

        }
    }
}
.header-btn{
    border: 1px solid var(--orange-button);
        color: var(--header-white-color);
        background-color: #0000;
        border-radius: 8px;
        padding: 10px 30px;
}

.header-menu_link {
    text-decoration: none; 
    position: relative; 
  }
  
  .header-menu_link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color:var(--caption-grey); 
    opacity: 0; /* Изначально невидимо */

    transition: transform 0.4s ease-out;
  }
  
  .header-menu_link:hover::after {
transform: scaleX(1);
    opacity: 1; /* Появляется при наведении */
  }
  .header-menu_link:hover{
    font-weight: 700;
  }

}