*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  line-height: 1.15;
}

body {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-color: currentColor;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

:root {
  --header-white-color: #fffdfa;
  --black-main-text-color: #000;
  --black-secondary-text-color: #0d0d0e;
  --black-button-color: #262525;
  --select-bg-color: #f8f3ed;
  --border-grey: #d2d2d2;
  --caption-grey: #d9d9d9;
  --light-yellow: #f0e9dd;
  --brown-gradient: linear-gradient(91deg, #7e7b79 0.66%, #524d45 28.33%, #211e1e 100.76%);
  --footer-background-color: linear-gradient(0deg, #0003 0%, #0003 100%), #3a3838;
  --achievements-background-color: linear-gradient(92deg, #918f8d 0.35%, #d9a96e 100%);
  --feedback-background-color: linear-gradient(91deg, #cfc9c2 0.16%, #fffaf4 100.76%);
  --gradient-service-img: linear-gradient(0deg, #877d7d66 0%, #877d7d66 100%);
  --button-hover-gradient: linear-gradient(0deg, #0003 0%, #0003 100%), linear-gradient(92deg, #b16a12 4.34%, #d28f2c 38.72%, #deaa5e 66.5%, #aa640d 97.51%);
  --button-active-gradient: linear-gradient(0deg, #0003 0%, #0003 100%), #b4721d;
  --main-white-color: #fff;
  --modal-white-color: #fcfeff;
  --button-white-color: #e7e7e7;
  --main-order-gradient: linear-gradient(91deg, #cfc9c2 0.16%, #fffaf4 100.76%);
  --bronze-button: #b4721d;
  --orange-button: #faac39;
  --transition-speed: .8s cubic-bezier(.4, 0, .2, 1);
  --card-shadow: 0px 1px 6px #2e2f4214, 0px 1px 1px #2e2f4229, 0px 2px 1px #2e2f4214;
}

body {
  font-family: Roboto, sans-serif, Raleway;
  font-size: 14px;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul, li, a, p {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

img {
  display: block;
}

header {
  z-index: 900;
  opacity: .9;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.nav-links {
  display: none;
}

main {
  padding-top: 73px;
}

.header-container {
  border-bottom: 1px solid var(--header-white-color);
  background: var(--brown-gradient);
  justify-content: space-between;
  align-items: center;
  padding: 14px 15px;
  display: flex;
}

.header-link {
  color: #0000;
  text-decoration: none;
}

.header-link a:hover {
  color: inherit;
}

.name-logo {
  flex-flow: column wrap;
  align-items: center;
  display: flex;
}

.igor {
  color: var(--header-white-color);
  letter-spacing: .36px;
  text-transform: uppercase;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 1.4;
}

.header-logo {
  align-items: center;
  gap: 4px;
  display: flex;
}

.line-left {
  color: var(--header-white-color);
  width: 37px;
  height: 1px;
}

.line-right {
  color: var(--header-white-color);
  width: 45px;
  height: 1px;
}

.label {
  color: var(--header-white-color);
  letter-spacing: 2.88px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.4;
}

.scale-icon {
  fill: var(--header-white-color);
}

.header-btn {
  display: none;
}

.mobile-menu-open {
  background-color: #0000;
  border: none;
  padding: 0;
  line-height: 0;
}

.burger-menu svg {
  fill: var(--header-white-color);
  width: 24px;
  height: 24px;
}

.menu-img {
  display: none;
}

@media screen and (width >= 1280px) {
  .mobile-menu {
    display: none;
  }
}

@media screen and (width <= 1279px) {
  .mobile-menu {
    z-index: 1000;
    visibility: hidden;
    opacity: .9;
    pointer-events: none;
    transition: transform var(--transition-speed), visibility .5s linear, opacity .5s linear;
    background: linear-gradient(0deg, #000000d9 0%, #000 100%), #d3d3d3 url("mob-menu-w320.6ebc2ebb.webp") 50% / cover no-repeat;
    width: 100%;
    height: 100%;
    padding: 71px 36px;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .mobile-menu.is-open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(0);
  }

  .mobile-menu_close-btn {
    background-color: #0000;
    border: none;
    padding: 0;
    position: absolute;
    top: 22px;
    right: 15px;
  }

  .list-mobile_nav {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
  }

  .mobile-menu_link {
    color: var(--header-white-color);
    font-family: Roboto;
    line-height: 1.4;
  }
}

.order-btn {
  border: 1px solid var(--orange-button);
  color: var(--header-white-color);
  background-color: #0000;
  border-radius: 8px;
  padding: 14px 30px;
}

footer {
  background: var(--footer-background-color);
}

.footer-container {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 320px;
  margin: 0 auto;
  padding: 14px 16px;
  display: flex;
}

.all-rights h3, .socials h3, .logo-slogan {
  color: var(--main-white-color);
  letter-spacing: .1px;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.socials h3 {
  text-align: center;
  margin-bottom: 8px;
}

.logo-slogan {
  text-align: center;
  margin-top: 8px;
  font-size: 10px;
}

.social-icons {
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.s-icon {
  width: 20px;
  height: 20px;
}

.hidden-viber, .footer-nav-container {
  display: none;
}

.all-rights {
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  padding-bottom: 14px;
  display: flex;
}

.all-rights h3 a {
  color: inherit;
  text-decoration: none;
}

.all-rights h3 a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main-slogan-container {
  background-color: #0000;
  background-image: linear-gradient(0deg, #02020266 1.01%, #b1aaaa66 101.01%), url("mob-main-slogan.7601e14e.webp");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 478px;
  display: inline-flex;
}

.position-container {
  flex-direction: column;
  align-items: center;
  gap: 120px;
  display: flex;
}

.contacts-container {
  background: linear-gradient(0deg, #dcdad84d 0% 100%), #d3d3d3 url("mob-menu-w393.77e9ac1b.webp") 50% / cover no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 546px;
  display: inline-flex;
}

.slogan-text h1 {
  color: var(--header-white-color);
  text-align: center;
  text-transform: uppercase;
  width: 306px;
  margin-bottom: 40px;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 1.4;
}

.slogan-text h3 {
  color: var(--header-white-color);
  text-align: center;
  width: 278px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.slogan-text {
  flex-direction: column;
  align-items: center;
  width: 306px;
  display: flex;
}

.slogan-order-btn {
  color: var(--button-white-color);
  width: 278px;
  font-weight: 700;
  line-height: 1.4;
}

.contact-slogan-text {
  justify-content: flex-start;
  margin-top: 100px;
  margin-bottom: 101px;
}

.contact-slogan-text h1 {
  color: var(--black-main-text-color);
  width: 236px;
  margin-bottom: 28px;
}

.contact-slogan-text h3 {
  color: var(--black-main-text-color);
}

.contact-slogan-btn {
  border: 1px solid var(--Bronze-Button, #b4721d);
  background: var(--Bronze-Button, linear-gradient(0deg, #0003 0%, #0003 100%), #b4721d);
  color: var(--header-white-color);
  border-radius: 8px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  margin: 0 14px 120px;
  padding: 14px 30px;
  font-weight: 700;
  line-height: 1.4;
}

.hero-container {
  background: var(--brown-gradient);
  padding: 40px 16px;
}

.hero-name {
  color: var(--header-white-color);
  margin-bottom: 16px;
  font-family: Merriweather;
  line-height: 1.4;
}

.hero-name h2 {
  margin-bottom: 8px;
  font-size: 14px;
}

.hero-name h1 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}

.hero-container img {
  border-radius: 4px;
  width: 100%;
}

.hero-section-text {
  margin-top: 24px;
}

.hero-section-text p {
  color: var(--header-white-color);
  font-family: Roboto;
  line-height: 1.4;
}

.tab-pc {
  display: none;
}

.achievements-container {
  background: var(--achievements-background-color);
  padding: 40px 16px;
}

.achievements {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.achievements li {
  text-align: center;
}

.up-text {
  color: #0d0d0e;
  flex-direction: column;
  gap: 15px;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.bot-text {
  font-family: Roboto;
  font-weight: 600;
}

#ach-line {
  border-color: #0d0d0e;
  border-radius: 1px;
  width: 1px;
  height: 40px;
  transform: rotate(90deg);
}

.areas-section-container {
  background: var(--brown-gradient);
  padding: 40px 16px;
}

.areas-section-container h1 {
  color: var(--header-white-color);
  margin-bottom: 24px;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 1.4;
}

.grid-container {
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 32px), 1fr));
  gap: 12px;
  display: grid;
}

.product-card {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.main-card-image, .card-image {
  width: 100%;
  height: 94px;
  transition: transform .5s;
}

.overflow-container {
  overflow: hidden;
}

.main-card-image:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.image-1 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-2_352x234.ae81f88a.webp") 50% / cover no-repeat;
}

.image-2 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("admin-practice_w363.af0dbddf.webp") 50% / cover no-repeat;
}

.image-3 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-3_352x234.d607e2b5.webp") 50% / cover no-repeat;
}

.image-4 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-4_352x234.5ce28b94.webp") 50% / cover no-repeat;
}

.image-5 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-1_352x234.1058e0e5.webp") 50% / cover no-repeat;
}

.image-6 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("tax-law.b87e03e0.webp") 50% / cover no-repeat;
}

.image-7 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-7_352x234.b146f3b3.webp") 50% / cover no-repeat;
}

.image-8 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("area-8_352x234.8364a4b9.webp") 50% / cover no-repeat;
}

.image-9 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("fintech.a00ec36b.webp") 50% / cover no-repeat;
}

.image-10 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("debt-recovery.9dc00800.webp") 50% / cover no-repeat;
}

.image-11 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("debtor-protection.953d740c.webp") 50% / cover no-repeat;
}

.image-12 {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("labor-law.b34fbf56.webp") 50% / cover no-repeat;
}

.product-card:hover .card-image {
  transform: scale(1.15);
}

.caption {
  text-align: center;
  background: var(--caption-grey);
  align-content: center;
  height: 42px;
  padding: 6px 2px;
}

.caption img {
  display: none;
}

.caption h3 {
  color: var(--black-secondary-text-color);
  text-align: center;
  font-family: Merriweather;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.caption h3:hover {
  color: #875e2a;
}

.order-section-container {
  background: var(--main-order-gradient);
  padding: 50px 0 0;
}

.order-image {
  background-color: #0000;
  background-image: linear-gradient(0deg, #b9b9b91a 0% 100%), url("igor-2_w393.cd2cf120.webp");
  background-position: 0 0, center;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 4px;
  width: 100%;
  height: 388px;
}

.order-section-container img {
  width: 100%;
  margin: 0 auto;
}

.contacts-order-section {
  display: none;
}

.order-main {
  background: var(--header-white-color);
  padding: 20px 15px 0;
}

.call-to-action {
  padding: 0 15px;
}

.order-main h2, .call-to-action h2 {
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 1.4;
}

.order-main h3, .call-to-action h3 {
  color: var(--black-secondary-text-color);
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.contacts {
  padding: 0;
}

.order-slogan, .order-form {
  background: var(--header-white-color);
  flex-direction: column;
  padding: 20px 15px;
  display: flex;
}

.order-slogan {
  background: none;
}

.order-slogan h2, .order-form h2 {
  color: var(--black-main-text-color);
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
}

.order-slogan h3, .order-form h3 {
  color: var(--black-secondary-text-color);
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.order-slogan h3 {
  margin-bottom: 0;
}

.form, .form-label {
  flex-direction: column;
  display: flex;
}

.form-label {
  color: var(--black-main-text-color);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
}

.form-input {
  background-color: #0000;
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px 8px;
}

.input-tel, .input-name {
  margin-bottom: 8px;
}

.btn-submit {
  border: 3px solid var(--bronze-button);
  color: var(--black-button-color);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 14px 40px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
}

.btn-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.main-socials {
  background: var(--header-white-color);
  padding: 0 15px 20px;
}

.main-socials .social-container {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.main-social-icons {
  justify-content: space-between;
  gap: 9.375vw;
  margin-top: 12px;
  display: flex;
}

.social-icon-block a {
  flex-direction: column;
  align-items: center;
  gap: 2px;
  display: flex;
}

.main-social-icon {
  width: 20px;
  height: 20px;
}

.social-title {
  color: var(--black-secondary-text-color);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.adress-section-container {
  background: var(--feedback-background-color);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 24px 0 10px;
  display: flex;
}

.contact-container {
  padding: 48px 0 10px;
}

.adress-section-container h2 {
  color: var(--black-main-text-color);
  text-align: center;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 1.4;
}

.adress, .tel-number, .mail {
  display: flex;
}

.adress p, .tel-number a, .mail a {
  color: var(--black-main-text-color);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.tel-number svg {
  width: 16px;
  height: 16px;
}

.adress svg, .tel-number svg, .mail svg {
  margin-right: 4px;
}

.map {
  border: none;
  width: 100%;
  height: 200px;
}

.adress > nav > a {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.no-scroll {
  overflow: hidden;
}

.feedback-slider-section {
  background: var(--feedback-background-color);
}

.feedback-container, .feedback-cases-container {
  padding: 40px 15px;
}

.feedback-container .feedback-img-cases, .feedback-cases-container .feedback-img-cases, .feedback-img {
  background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url("feedback_2x_mob_h265.5eedfca7.webp") 50% / cover no-repeat;
  border-radius: 4px;
  height: 187px;
}

.feedback-container h2, .feedback-cases-container h2 {
  color: #000;
  margin-bottom: 24px;
  font-family: Merriweather;
  font-size: 16px;
  line-height: 1.4;
}

.slider-slide {
  border: 1px solid var(--border-grey);
  background-color: #d3d3d3;
  background-image: linear-gradient(#ffffff80, #fff), url("mob-menu-w320.6ebc2ebb.webp");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  height: 260px;
  margin-left: 10px;
  padding: 10px;
}

.slider-slide h3 {
  color: #000;
  font-family: Merriweather;
  font-size: 11px;
  line-height: 1.4;
}

.stars {
  display: none;
}

.stars-mob {
  justify-content: flex-start;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 12px;
  display: flex;
}

.stars-mob > li > svg {
  width: 10px;
  height: 10px;
}

.quotes {
  margin-bottom: 4px;
  display: flex;
}

.quotes svg {
  width: 5px;
  height: 7px;
}

.slider-text {
  color: #000;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.quotes-bottom {
  justify-content: flex-end;
}

.cases-feedback-slider, .main-feedback-slider {
  margin-top: 12px;
}

.slick-slide {
  margin-left: 20px;
}

.slider-controls {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.cases-prev-slide, .cases-next-slide, .main-prev-slide, .main-next-slide {
  background: none;
  border: none;
}

.cases-prev-slide svg, .cases-next-slide svg, .main-prev-slide svg, .main-next-slide svg {
  width: 32px;
  height: 32px;
}

.mystory-section-pc, .main-order-section-message-pc {
  display: none;
}

.mystory-container {
  background: var(--feedback-background-color);
  padding: 50px 16px;
}

.mystory-name {
  color: var(--black-main-text-color);
}

.mystory-name h2 {
  margin-bottom: 8px;
  font-size: 13px;
}

.mystory-name h1 {
  margin-bottom: 8px;
}

.mystory-name h3 {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.mystory-container img {
  border-radius: 4px;
  width: 100%;
}

.aboutme-img {
  display: none;
}

.mystory-section-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
}

#flex-text {
  margin-top: 24px;
}

#block-text {
  margin-bottom: 24px;
}

#mystory-slogan-text {
  display: none;
}

.order-btn-aboutme {
  color: var(--black-button-color);
  border: 1px solid var(--bronze-button);
  margin: 0 auto;
  display: block;
}

.whyme-section-container {
  background-color: #fff;
  gap: 4px;
  padding: 50px 16px;
}

.whyme-section-container h1 {
  color: #000;
  margin-bottom: 24px;
  font-family: Merriweather;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.online-support {
  text-align: center;
  color: #000;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  font-family: Roboto;
  font-size: 12px;
  display: flex;
}

.online-support img {
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
}

.online-support h4 {
  margin-bottom: 4px;
  font-weight: 700;
}

.online-support p {
  text-align: center;
  font-weight: 400;
}

.order-form-container {
  background: var(--header-white-color);
  padding: 0 0 30px;
}

.error-message {
  color: brown;
  margin-top: 5px;
  font-weight: bold;
}

.order-igor-tab-img {
  display: none;
}

.service-types-section-container {
  background: var(--main-order-gradient);
  padding: 50px 15px 20px;
}

.service-types-section-container h2 {
  color: var(--black-main-text-color);
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: Merriweather;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.service-types-section-container p {
  color: var(--black-main-text-color);
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.custom-select {
  display: block;
  position: relative;
}

.custom-select select {
  appearance: none;
}

.select-arrow {
  position: absolute;
  top: 43%;
  right: 30px;
}

select {
  background: var(--select-bg-color);
  color: var(--black-secondary-text-color);
  text-align: center;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 10px;
  font-family: Merriweather;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.migration-serv-container {
  background: var(--feedback-background-color);
  padding: 0 15px 50px;
}

.main-order-section-pc, .side-buttons-pc-flex-container, .side-buttons {
  display: none;
}

.service-title {
  flex-direction: column;
  align-self: center;
  display: flex;
}

.service-title h1 {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

.migration-serv-container img {
  width: 40px;
  height: 40px;
  margin: 0 auto 4px;
  display: block;
}

.migration-serv-container h1 {
  margin: 0 auto 12px;
  display: block;
}

.migration-serv-img {
  border-radius: 4px;
  height: 288px;
}

.migration-img {
  background: var(--gradient-service-img), url("migration-image.7f5e3f9b.webp") lightgray 50% / cover no-repeat;
}

.criminal-img {
  background: var(--gradient-service-img), url("area-2_352x234.ae81f88a.webp") lightgray 50% / cover no-repeat;
}

.admin-img {
  background: var(--gradient-service-img), url("admin-practice_w363.af0dbddf.webp") lightgray 50% / cover no-repeat;
}

.civil-img {
  background: var(--gradient-service-img), url("area-4_352x234.5ce28b94.webp") lightgray 50% / cover no-repeat;
}

.intellectual-img {
  background: var(--gradient-service-img), url("area-1_352x234.1058e0e5.webp") lightgray 50% / cover no-repeat;
}

.tax-img {
  background: var(--gradient-service-img), url("tax-law.b87e03e0.webp") lightgray 50% / cover no-repeat;
}

.fintech-img {
  background: var(--gradient-service-img), url("fintech.a00ec36b.webp") lightgray 50% / cover no-repeat;
}

.military-img {
  background: var(--gradient-service-img), url("military-law.2c92ed13.webp") lightgray 50% / cover no-repeat;
}

.business-law-img {
  background: var(--gradient-service-img), url("labor-image_2x.f6bfcde0.webp") lightgray 50% / cover no-repeat;
}

.labor-img {
  background: var(--gradient-service-img), url("area-8_352x234.8364a4b9.webp") lightgray 50% / cover no-repeat;
}

.debt-recovery-img {
  background: var(--gradient-service-img), url("debt-recovery.9dc00800.webp") lightgray 50% / cover no-repeat;
}

.debtor-protection-img {
  background: var(--gradient-service-img), url("debtor-protection.953d740c.webp") lightgray 50% / cover no-repeat;
}

.migration-serv-container article {
  margin-top: 20px;
}

.migration-serv-container article p {
  color: var(--black-main-text-color);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.services-btn {
  color: var(--black-button-color);
  border: 1px solid var(--bronze-button);
  background-color: #0000;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 14px 30px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.4;
}

.intel-list {
  margin-bottom: 20px;
}

.intel-list h2 {
  color: var(--black-button-color);
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.intel-title {
  padding: 0 15px;
}

.labor-law-items li, .debt-recovery-items li, .intel-title li {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: disc !important;
}

.debt-recovery-items {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 15px;
}

.labor-law-items {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 15px;
}

.get-title {
  color: var(--black-button-color);
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.whyme-title p {
  font-size: 12px;
  line-height: 1.4;
}

.service-item {
  margin-top: 18px;
  margin-bottom: 18px;
}

.service-item p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.service-details {
  align-items: center;
  gap: 8px;
  margin-bottom: 7.5px;
  display: flex;
}

.service-details svg {
  width: 24px;
  height: 24px;
}

.service-details h3 {
  color: var(--black-button-color);
  width: 83vw;
  font-family: Roboto;
  font-size: 12px;
}

.service-item-bottom-text {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.4;
}

.page-form-2 h2, .page-form-2 h3 {
  display: none;
}

.steps-section-container {
  background: var(--select-bg-color);
  padding: 50px 15px;
}

.four-steps {
  color: var(--black-main-text-color);
  margin-bottom: 24px;
  line-height: 1.4;
}

.four-steps h2 {
  width: 288px;
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 700;
}

.four-steps p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.first-step {
  flex-direction: column;
  align-items: center;
  margin-bottom: 4px;
  padding: 8px;
  display: flex;
}

.step {
  color: var(--header-white-color);
  background-color: #524545;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-bottom: 8px;
  font-family: Merriweather;
  font-weight: 700;
  line-height: 1;
  display: flex;
  box-shadow: 3px 4px 14.8px #86807b;
}

.first-step h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.step-details {
  padding: 0 15px;
}

.step-details li {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: disc !important;
}

@media screen and (width <= 767px) {
  .steps-container {
    opacity: 0;
    transition: transform var(--transition-speed), visibility .5s linear, opacity .5s linear;
    transform: translateX(-100%);
  }
}

.cases {
  background: var(--main-order-gradient);
  padding: 70px 15px;
}

.cases-about {
  margin-bottom: 24px;
  line-height: 1.4;
}

.cases-about h1 {
  color: var(--black-main-text-color);
  margin-bottom: 8px;
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 700;
}

.cases-about p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.cases-pc-container {
  display: none;
}

.case-description {
  color: var(--black-main-text-color);
  margin-top: 10px;
  line-height: 1.4;
}

.case-description h2 {
  font-family: Merriweather;
  font-size: 13px;
  font-weight: 700;
}

.adm-law {
  margin-top: 8px;
  margin-bottom: 8px;
}

.case-idea {
  margin-bottom: 12px;
}

.resolution {
  margin-bottom: 4px;
}

.case-context {
  margin-bottom: 16px;
}

.case-description h3 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
}

.case-description p {
  font-size: 12px;
  font-weight: 400;
}

.case-description ol, .case-description ul {
  padding: 0 15px;
}

.case-description ol > li, .case-description ul > li {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: disc;
}

.case-flex-container-right ul {
  padding-left: 15px;
}

.case-flex-container-right ul > li {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: disc;
}

.case-description ol > li {
  list-style-type: decimal;
}

.main-idea {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 12px;
}

.cases-btn {
  margin-top: 12px;
  margin-bottom: 10px;
}

.main-card-image, .card-image {
  transition: transform var(--transition-speed);
  object-fit: cover;
  width: 100%;
  display: block;
}

.card-image:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.card-image-container {
  transition: transform var(--transition-speed);
}

.case-card-module {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 15px;
  margin-bottom: 10px;
  display: flex;
}

.wide-image {
  transition: transform var(--transition-speed);
  flex-basis: 100%;
}

.narrow-image {
  transition: transform var(--transition-speed);
  flex-basis: calc(50% - 7.5px);
  height: 100%;
}

.height-img {
  height: 122px;
  overflow: hidden;
}

.euro-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("euro-wide.3b73df7a.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.euro-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("euro-narrow.2ada5e8b.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.bispart-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bispart-narrow.c9c62a9f.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.bispart-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bispart-wide.761aa2cf.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.cuttingmarriage-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("cuttingmarriage-narrow.5cc99d8c.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.cuttingmarriage-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("cuttingmarriage-wide.0ce1f15d.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.justice-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("justice-wide.44b222aa.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.justice-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("justice-narrow.fd730018.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.criminal-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("criminal-wide.7814175b.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.criminal-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("criminal-narrow.ca6b00c9.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.alimenty-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("alimenty-wide.a30b32f8.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.alimenty-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("alimenty-narrow.387eed22.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.land-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("land-wide.ba7e5970.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.land-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("land-narrow.43d14bee.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.bayer-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bayer-wide.029cb664.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.bayer-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bayer-narrow.4be871ef.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.custom-wide {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("custom-wide.b0a6d74a.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.custom-narrow {
  background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("custom-narrow.65e39a0f.webp") 50% / cover no-repeat;
  border-radius: 4px 4px 0 0;
  height: 100%;
}

.caption {
  color: var(--black-secondary-text-color);
  text-align: center;
  font-family: Merriweather;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.case-caption {
  font-size-adjust: .7;
  background: var(--light-yellow);
  border-radius: 0 0 4px 4px;
  justify-content: center;
  align-items: center;
  height: 58px;
  display: flex;
}

.is-hidden {
  display: none;
}

.thanks-header-modal, .header-modal {
  background: var(--brown-gradient);
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.header-modal-container {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

.header-modal-container svg {
  width: 18px;
  height: 18px;
}

.header-modal-container h2 {
  color: var(--modal-white-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.close-button {
  background-color: #0000;
  border: none;
}

.close-button svg {
  width: 18px;
  height: 18px;
}

.modal-flex-container img {
  display: none;
}

.modal-form {
  background: var(--feedback-background-color);
}

#padding-container {
  padding-bottom: 0;
}

.modal-form h2 {
  text-align: center;
}

.modal-form h3 {
  text-align: center;
  margin-top: 12px;
}

.input-modal-name {
  background-color: #0000;
  margin-bottom: 12px;
}

.input-modal-tel {
  background-color: #0000;
  height: 38px;
  margin-bottom: 0;
}

#wrightme {
  text-align: left;
  margin-top: 0;
}

.modal-order-btn, .thanks-modal-btn {
  background: var(--feedback-background-color);
  justify-content: center;
  padding: 20px 0;
  display: flex;
}

.modal-social-icon {
  width: 20px;
  height: 20px;
}

.btn-submit {
  background-color: #0000;
}

.btn-icon svg {
  width: 16px;
  height: 16px;
}

.thanks-main-modal {
  padding: 20px 0;
}

.thanks-main-modal h1 {
  color: var(--black-secondary-text-color);
  text-align: center;
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

.thanks-main-modal .thanks-main-modal-text {
  text-align: center;
  flex-direction: column;
  gap: 8px;
  margin: 20px;
  display: flex;
}

.thanks-main-modal .thanks-main-modal-socials {
  padding: 8px 16px;
}

.thanks-main-modal .thanks-main-modal-socials h3 {
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
}

.thanks-main-modal .thanks-main-modal-socials ul {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.thanks-backdrop, .backdrop {
  opacity: 0;
  box-shadow: var(--card-shadow);
  transition: opacity var(--transition-speed);
  z-index: 9999;
  pointer-events: none;
  background-color: #000c;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.thanks-backdrop.is-visible, .backdrop.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.thanks-section-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thanks-flex-container {
  background: var(--header-white-color);
  justify-content: center;
  padding: 20px 16px;
  display: flex;
}

.thanks-flex-container .thanks-modal-img {
  display: none;
}

.thanks-flex-container .thanks-main-modal {
  padding: 0;
}

.thanks-flex-container .thanks-main-modal .thanks-main-modal-text {
  gap: 9px;
  margin: 20px 0;
}

.thanks-flex-container .thanks-main-modal .thanks-main-modal-text p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
}

.thanks-flex-container .thanks-main-modal-socials {
  padding: 8px 0;
}

.thanks-flex-container .thanks-main-modal-socials h3 {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
}

.thanks-modal-btn {
  padding: 20px 16px;
}

.thanks-btn-submit {
  border: 3px solid var(--bronze-button);
  color: var(--black-button-color);
  background: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 268px;
  margin: 0;
  padding: 14px 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
}

.thanks-btn-submit .btn-icon {
  display: none;
}

.thanks-btn-submit:hover {
  background: var(--button-hover-gradient);
  color: var(--header-white-color);
  cursor: pointer;
}

@media screen and (width >= 768px) {
  .header-container {
    padding: 22px 60px;
  }

  .nav-links {
    display: none;
  }

  .header-btn {
    padding: 10px 30px;
    display: block;
  }

  .header-btn:hover {
    cursor: pointer;
    background: var(--button-hover-gradient);
  }

  .header-btn:active {
    background: var --button-active-gradient;
  }

  main {
    padding-top: 106px;
  }

  .igor {
    letter-spacing: .56px;
    text-align: center;
    font-size: 28px;
  }

  .header-name-logo {
    margin-right: 0;
  }

  .name-logo .header-logo {
    gap: 9px;
  }

  .line-left {
    width: 97px;
  }

  .scale-icon {
    width: 20px;
    height: 20px;
  }

  .line-right {
    width: 50px;
  }

  .mobile-menu {
    padding: 78px 120px;
  }

  .mobile-menu_container {
    flex-flow: column wrap;
    align-content: flex-start;
    gap: 45px;
    display: flex;
  }

  .header-nav-menu {
    gap: 20px !important;
  }

  .mobile-menu_link {
    color: var(--header-white-color);
    font-size: 14px;
  }

  .header-nav-menu-btn {
    color: #e7e7e7;
    width: 263px;
    padding: 14px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
  }

  .menu-img {
    display: block;
  }

  .menu-img img {
    width: 100px;
    height: 100px;
  }

  .main-slogan-container {
    background-image: linear-gradient(0deg, #02020266 1.01%, #b1aaaa66 101.01%), url("tab-main-slogan.2a0d1062.webp");
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    height: 481px;
    margin-bottom: -5px;
    position: relative;
  }

  .position-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    display: flex;
    position: absolute;
    left: 60px;
  }

  .hero-container {
    gap: 24px;
    padding: 70px 60px;
    display: flex;
  }

  .hero-container img {
    border-radius: 4px;
    width: 312px;
    height: 429px;
  }

  .hero-name {
    margin-top: 25px;
    margin-bottom: 24px;
  }

  .hero-name h2 {
    font-size: 16px;
  }

  .hero-name h1 {
    font-size: 20px;
  }

  .slogan-text h1 {
    text-align: left;
    font-size: 20px;
  }

  .slogan-text h3 {
    text-align: left;
    width: auto;
    margin-bottom: 60px;
    font-size: 16px;
  }

  .slogan-order-btn {
    padding: 14px 40px;
    font-size: 16px;
  }

  .hero-details, .tab-pc {
    display: block;
  }

  .tab-pc p {
    font-size: 15px;
    font-weight: 400;
  }

  .mob {
    display: none;
  }

  .slogan-order-btn:hover {
    cursor: pointer;
    background: var(--button-hover-gradient);
  }

  .slogan-order-btn:active {
    background: var(--button-active-gradient);
  }

  .achievements-container {
    padding: 12px 60px;
  }

  .achievements-container .achievements {
    flex-direction: row;
    justify-content: space-around;
  }

  .achievements-container #ach-line {
    transform: rotate(0);
  }

  .achievements-container .up-text {
    gap: 0;
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
  }

  .achievements-container .up-text .figures {
    font-family: Merriweather;
  }

  .achievements-container .up-text .bot-text {
    padding: 16px 0;
    font-family: Merriweather;
  }

  .areas-section-container {
    padding: 70px 60px;
  }

  .areas-section-container h1 {
    font-size: 24px;
  }

  .areas-section-container .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(calc(33.3333% - 56px), 1fr));
    gap: 24px;
  }

  .areas-section-container .main-card-image, .areas-section-container .card-image {
    height: 136px;
  }

  .areas-section-container .caption {
    align-items: center;
    gap: 9px;
    height: 64px;
    padding: 12px 8px;
    display: flex;
  }

  .areas-section-container .caption img {
    width: 24px;
    height: 24px;
    display: block;
  }

  .areas-section-container .caption h3 {
    text-align: left;
    font-size: 14px;
  }

  .slider-slide {
    height: 280px;
    width: 312px !important;
  }

  .slider-slide h3 {
    font-size: 14px;
  }

  .stars {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .stars > li > svg {
    width: 20px;
    height: 20px;
  }

  .quotes svg {
    width: 5px;
    height: 9px;
  }

  .slider-text {
    font-size: 12px;
  }

  .order-section-container img {
    height: 465px;
  }

  .order-image {
    background-color: #0000;
    background-image: linear-gradient(0deg, #b9b9b91a 0% 100%), url("igor-main-order-image_tab_2x.7de626b1.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    height: 621px;
  }

  .order-slogan, .order-main {
    padding: 24px 16px 0;
  }

  .order-main > h2 {
    margin-bottom: 12px;
    font-family: Merriweather;
    font-size: 18px;
  }

  .order-main > h3 {
    margin-bottom: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
  }

  .form-label {
    font-size: 16px;
  }

  .btn-submit {
    align-items: center;
    width: 30vw;
    margin: 34px auto;
  }

  .btn-submit:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .btn-submit:hover svg {
    fill: var(--header-white-color);
  }

  .main-socials {
    padding: 0 16px 32px;
  }

  .main-socials h3 {
    font-size: 16px;
  }

  .main-socials .social-container .main-social-icons {
    justify-content: flex-start;
    gap: 80px;
    width: 300px;
  }

  .main-social-icons {
    gap: 15px;
    margin-top: 20px;
  }

  .main-social-icons li .social-icon-block > a {
    flex-direction: row;
    gap: 8px;
    display: flex;
  }

  .social-title {
    font-size: 15px;
  }

  .main-social-icon {
    width: 24px;
    height: 24px;
  }

  .contacts-order-section {
    display: block;
  }

  .main-order-section-mob-contacts {
    display: none;
  }

  .adress-section-container {
    padding: 0 0 10px;
  }

  .adress-section-container h2 {
    color: var(--black-button-color);
    margin-bottom: 26px;
    font-size: 24px;
  }

  .adress-section-container .adress p, .adress-section-container .tel-number a, .adress-section-container .mail a {
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    display: flex;
  }

  .adress-section-container .adress {
    margin-bottom: 12px;
  }

  .adress-section-container .adress svg, .adress-section-container .tel-number svg, .adress-section-container .mail svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .adress-section-container .map {
    width: 100%;
    height: 467px;
  }

  .contacts-container {
    background: linear-gradient(0deg, #dcdad84d 0% 100%), #d3d3d3 url("tab-menu-2x.f8d5bd80.webp") 50% / cover no-repeat;
    justify-content: flex-start;
    height: 485px;
    display: flex;
  }

  .contact-slogan-text {
    color: var(--black-secondary-text-color);
    align-items: center;
    width: 315px;
    margin-top: 107.13px;
    margin-bottom: 60px;
    display: flex;
  }

  .contact-slogan-text h1 {
    text-align: center;
    width: 648px;
    margin-bottom: 28px;
  }

  .contact-slogan-text h3 {
    text-align: center;
    margin-bottom: auto;
  }

  .contact-slogan-btn {
    color: var(--black-secondary-text-color);
    background: none;
    width: 263px;
    margin: 0 auto;
    padding: 14px 40px;
  }

  .contact-slogan-btn:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .feedback-container, .feedback-cases-container {
    padding: 70px 60px;
  }

  .feedback-container h2, .feedback-cases-container h2 {
    font-size: 24px;
  }

  .feedback-container img, .feedback-cases-container img {
    display: none;
  }

  .feedback-container .feedback-img, .feedback-cases-container .feedback-img {
    background-image: url("feedback_2x_h265.3d1d36bb.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    width: 100%;
    height: 265px;
  }

  .slider-wrapper .slick-slide {
    margin: 0 0 0 10px;
    padding: 0;
  }

  .slider-slide {
    height: 280px;
    margin-left: -20px;
    width: 312px !important;
  }

  .cases-prev-slide svg, .cases-next-slide svg, .main-prev-slide svg, .main-next-slide svg {
    width: 40px;
    height: 40px;
  }

  .stars-mob {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .stars-mob > li > svg {
    width: 14px;
    height: 14px;
  }

  .quotes {
    margin-bottom: 8px;
  }

  .quotes-bottom {
    margin-top: 8px;
  }

  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 32px 60px 10px;
    display: flex;
  }

  .footer-nav-container {
    display: block;
  }

  .list-mobile_nav {
    gap: 8px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobile-menu_link, .logo-slogan {
    font-size: 15px;
  }

  .name-logo {
    margin-right: 100px;
  }

  .bm-footer-tab, .socials h3 {
    display: none;
  }

  .social-icons {
    flex-direction: column;
  }

  .s-icon {
    fill: var(--main-white-color);
    width: 16px;
    height: 16px;
  }

  .s-icon:hover {
    fill: red;
  }

  .hidden-viber {
    display: block;
  }

  .all-rights {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-bottom: 10px;
    display: flex;
  }

  .all-rights h3 {
    font-size: 12px;
  }

  .header-modal-container svg {
    width: 24px;
    height: 24px;
  }

  .header-modal-container h2 {
    font-size: 20px;
  }

  .close-button > svg {
    width: 24px;
    height: 24px;
  }

  .modal-form {
    background: var(--footer-background-color);
  }

  .modal-flex-container {
    background: var(--feedback-background-color);
    justify-content: space-evenly;
    gap: 18px;
    padding: 20px 60px;
    display: flex;
  }

  .modal-flex-container img {
    border-radius: 4px;
    height: 335px;
    margin: 0;
    display: block;
  }

  .modal-block-container, .modal-block-container .modal-form {
    background: none;
  }

  .modal-social-icon {
    width: 20px;
    height: 20px;
  }

  .social-title-modal {
    color: var(--black-secondary-text-color);
    font-size: 12px;
  }

  .modal-submit-btn {
    width: 220px;
    margin: 0;
  }

  .order-form {
    background: none;
    gap: 24px;
    padding: 0;
  }

  .order-form > h2 {
    color: var(--black-secondary-text-color);
    margin: 0;
    font-size: 24px;
  }

  .order-form > h3 {
    margin: 0;
    font-size: 15px;
  }

  .input-modal-name, .input-modal-tel {
    color: var(--black-secondary-text-color);
    font-size: 16px;
  }

  .input-modal-name {
    margin-bottom: 20px;
  }

  .main-socials.modal-form {
    background: var(--feedback-background-color);
    border-radius: 8px;
    margin-top: 24px;
    padding: 0;
  }

  #wrightme {
    color: var(--black-secondary-text-color);
    font-size: 15px;
  }

  .modal-icons {
    gap: 25px;
    margin-top: 12px;
  }

  .main-social-icons li .social-icon-block > a {
    gap: 4px;
  }

  .btn-icon svg {
    width: 20px;
    height: 20px;
  }

  .thanks-section-container {
    width: 739px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .thanks-flex-container {
    background: var(--header-white-color);
    justify-content: center;
    gap: 25px;
    padding: 20px 60px;
    display: flex;
  }

  .thanks-flex-container .thanks-modal-img {
    background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url("thanks-modal-tab_2x.031f3d5b.webp") 50% / cover no-repeat;
    border-radius: 4px;
    width: 214px;
    height: 290px;
    display: block;
  }

  .thanks-flex-container .thanks-main-modal {
    width: 381px;
    padding: 0;
  }

  .thanks-flex-container .thanks-main-modal .thanks-main-modal-text {
    gap: 20px;
    margin: 40px 0;
  }

  .thanks-flex-container .thanks-main-modal .thanks-main-modal-text p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
  }

  .thanks-flex-container .thanks-main-modal-socials {
    padding: 12px 0;
  }

  .thanks-flex-container .thanks-main-modal-socials h3 {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.4;
  }

  .thanks-modal-btn {
    padding: 20px 16px;
  }

  .social-icon-block a {
    flex-direction: row;
    gap: 4px;
    display: flex;
  }

  .thanks-btn-submit {
    border: 3px solid var(--bronze-button);
    color: var(--black-button-color);
    background: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 14px 60px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    display: flex;
  }

  .thanks-btn-submit .btn-icon {
    display: block;
  }

  .thanks-btn-submit:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .thanks-btn-submit:hover svg {
    fill: var(--header-white-color);
  }

  .mystory-section-pc, .main-order-section-message-pc {
    display: none;
  }

  .mystory-container {
    padding: 70px 60px;
  }

  .mystory-name {
    color: var(--black-secondary-text-color);
    margin: 0 0 24px;
  }

  .mystory-name h3 {
    font-weight: 700;
  }

  .aboutme-flex-container {
    gap: 24px;
    margin-bottom: 18px;
    display: flex;
  }

  .mystory-mob-img {
    display: none;
  }

  .aboutme-img {
    background: #d3d3d3 url("mystory-tab_2x.16a47c72.webp") center / cover no-repeat;
    border-radius: 4px;
    width: 312px;
    height: 360px;
    display: block;
  }

  .mystory-section-text {
    color: var(--black-secondary-text-color);
    margin: 0;
    font-size: 15px;
  }

  #flex-text {
    max-width: 312px;
    margin: 0;
  }

  #block-text {
    margin: 0;
  }

  .mystory-section-text p:not(:last-child) {
    margin-bottom: 9px;
  }

  #mystory-slogan-text {
    margin-top: 15px;
    margin-bottom: 24px;
    font-weight: 700;
    display: block;
  }

  .order-btn-aboutme {
    margin: 0 0 0 auto;
  }

  .order-btn-aboutme:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .whyme-section-container {
    background: var(--select-bg-color);
    padding: 70px 60px;
  }

  .whyme-section-container h1 {
    font-size: 24px;
  }

  .whyme-flex-container {
    gap: 24px;
    display: flex;
  }

  .online-support {
    color: var(--black-secondary-text-color);
    width: calc(33.3333% - 16px);
    padding: 0;
  }

  .online-support img {
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
  }

  .online-support h4 {
    margin-bottom: 8px;
    font-size: 15px;
  }

  .online-support p {
    font-size: 15px;
    line-height: 1.4;
  }

  .order-form-gradient-container {
    background: var(--feedback-background-color);
  }

  .main-order-section {
    display: block;
  }

  .order-section-container {
    padding: 70px 60px 50px;
  }

  .order-igor-mob-img {
    display: none;
  }

  .order-igor-tab-img {
    background: #d3d3d3 url("order-igor-tab_2x.fc9eb35a.webp") center / cover no-repeat;
    border-radius: 4px;
    width: 199px;
    height: 270px;
    display: block;
  }

  .page-form {
    gap: 0;
  }

  .page-form h2 {
    margin-bottom: 8px;
  }

  .page-form h3 {
    margin-bottom: 24px;
  }

  .order-form-flex-container {
    gap: 24px;
    display: flex;
  }

  .form-order-form {
    width: 425px;
  }

  .page-form-socials {
    background: none;
  }

  .page-form-label {
    font-size: 15px;
  }

  .form-input {
    font-size: 12px;
  }

  .page-form-btn {
    margin: 22px auto 0;
  }

  .page-form-btn:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .page-form-btn:hover svg {
    fill: var(--header-white-color);
  }

  .page-form-socials {
    width: 508px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  .page-form-social-title {
    margin-left: 4px;
  }

  .service-item-bottom-text {
    color: var(--black-button-color);
    font-size: 15px;
    font-weight: 400;
  }

  .service-types-section-container {
    padding: 70px 60px 34px;
  }

  .service-types-section-container h2 {
    font-size: 20px;
  }

  .select-container, .btn-title img, .side-buttons-pc-flex-container {
    display: none;
  }

  .btn-title img .migration-serv-container {
    padding: 0 60px 70px;
  }

  .main-order-section-pc {
    display: none;
  }

  .side-buttons {
    display: block;
  }

  .service-flex-container {
    gap: 24px;
    margin-bottom: 34px;
    display: flex;
  }

  .service-buttons {
    flex-direction: column;
    gap: 8px;
    display: flex;
  }

  .service-buttons li {
    background: var(--light-yellow);
    color: var(--black-secondary-text-color);
    border-radius: 4px;
    width: 230px;
    padding: 12px 10px;
    font-family: Merriweather;
    font-weight: 700;
    line-height: 1.4;
  }

  .service-buttons li a {
    color: var(--black-secondary-text-color);
  }

  .service-buttons li.select {
    background: var(--select-bg-color);
  }

  .intel-list h2 {
    font-size: 18px;
  }

  .labor-law-items li, .debt-recovery-items li, .intel-title li {
    font-size: 15px;
  }

  .service-title {
    align-self: auto;
  }

  .service-title-flex-container {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    display: flex;
  }

  .service-title-flex-container img {
    width: 50px;
    height: 50px;
    margin: 0;
  }

  .service-title-flex-container h1 {
    color: var(--black-secondary-text-color);
    margin: 0;
    font-size: 24px;
  }

  .migration-img {
    height: 300px;
  }

  .migration-serv-container article {
    margin-top: 14px;
  }

  .migration-serv-container article p {
    margin-bottom: 18px;
    font-size: 15px;
  }

  .services-btn {
    color: var(--black-button-color);
    width: 263px;
    margin: 0 0 0 auto;
    padding: 14px;
    font-size: 16px;
  }

  .whyme-title h2 {
    font-size: 18px;
  }

  .whyme-title p {
    font-size: 15px;
  }

  .service-details h3, .service-item p {
    color: var(--black-secondary-text-color);
    font-size: 15px;
  }

  .order-form-gradient-container-2 {
    background: var(--select-bg-color);
  }

  .page-form-hidden h2, .page-form-hidden h3 {
    display: none;
  }

  .page-form-hidden .page-form-2 h2, .page-form-hidden .page-form-2 h3 {
    color: var(--black-secondary-text-color);
    display: block;
  }

  .page-form-hidden .page-form-2 h2 {
    font-size: 24px;
    font-weight: 700;
  }

  .page-form-hidden .page-form-2 h3 {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .order-img {
    height: 303px;
  }

  .steps-section-container {
    padding: 100px 60px;
  }

  .four-steps {
    margin-bottom: 34px;
  }

  .four-steps h2 {
    width: auto;
    margin-bottom: 12px;
    font-size: 24px;
  }

  .four-steps p {
    font-size: 15px;
  }

  .flex-steps-container {
    flex-wrap: wrap;
    gap: 24px;
    display: flex;
  }

  .steps-container {
    width: calc(50% - 12px);
  }

  .cases-content {
    position: relative;
  }

  .case-description-container {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    position: static;
    transform: translateX(0);
  }

  .case-description-container.is-hidden {
    display: none;
  }

  .case-description-container.right-aligned {
    transform: translateX(-224px);
  }

  .case-card-pc-module:not(:last-child) {
    margin-bottom: 18px;
  }

  .card-flex-pc-container {
    gap: 24px;
    display: flex;
  }

  .card-flex-pc-container:not(:last-child) {
    margin-bottom: 18px;
  }

  .wide-image {
    max-width: 424px;
  }

  .narrow-image {
    height: 100%;
  }

  .height-img {
    height: 142px;
  }

  .caption {
    background: var(--caption-grey);
  }

  .case-caption {
    background: var(--light-yellow);
    font-size: 13px;
  }

  .euro-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("euro-wide-tab_2x.14283573.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .euro-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("euro-narrow-tab_2x.a1c7ebbb.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .bispart-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bispart-narrow-tab_2x.aa6f9261.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .bispart-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bispart-wideXXX-tab_2x.1e2125a8.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .cuttingmarriage-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("cuttingmarriage-narrow-tab_2x.9c6ab367.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .cuttingmarriage-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("cuttingmarriage-wide-tab_2x.1a4d5b3c.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .justice-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("justice-wide-tab_2x.c2666a33.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .justice-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("justice-narrow-tab_2x.d504484c.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .criminal-wide, .criminal-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("criminal-wide-tab_2x.502bfc82.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .alimenty-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("alimenty-wideXXX-tab_2x.3d5957d7.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .alimenty-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("alimenty-narrow-tab_2x.1451553b.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .land-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("land-wideXXX-tab_2x.2bbbeaae.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .land-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("land-narrow-tab_2x.ee65751b.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .bayer-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bayer-wideXXX-tab_2x.a388eb7e.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .bayer-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("bayer-narrow-tab_2x.744b01d6.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .custom-wide {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("custom-wideXXX-tab_2x.6852c298.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .custom-narrow {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("custom-narrow-tab_2x.2d6f046b.webp") 50% / cover no-repeat;
    border-radius: 4px 4px 0 0;
    height: 100%;
  }

  .case-card {
    margin-top: 18px;
  }

  .cases {
    padding: 70px 60px;
  }

  .cases-about h1 {
    font-size: 24px;
  }

  .cases-about p {
    width: 509px;
    font-size: 15px;
  }

  .cases-container {
    display: none;
  }

  .cases-pc-container {
    flex-direction: column;
    display: flex;
  }

  .case-card {
    flex-direction: row;
    gap: 24px;
    display: flex;
  }

  .case-flex-container-left {
    max-width: 55vw;
  }

  .case-flex-container-right {
    max-width: 26vw;
  }

  .case-description {
    margin-top: 0;
  }

  .case-description h2 {
    font-size: 18px;
  }

  .case-description .adm-law {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 16px;
  }

  .case-description h3 {
    font-size: 16px;
  }

  .case-description p {
    font-size: 15px;
  }

  .case-description .resolution {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 140%;
  }

  .case-description ol {
    padding-left: 15px;
  }

  .case-description ol > li {
    font-size: 15px;
  }

  .case-flex-container-right ul {
    padding-left: 15px;
  }

  .case-flex-container-right ul > li {
    font-size: 15px;
  }

  .main-idea {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 1.4;
  }

  .cases-btn {
    width: 200px;
    padding: 14px 0;
  }
}

@media screen and (width >= 1280px) {
  .mobile-menu-open {
    display: none;
  }

  header {
    background: var(--brown-gradient);
    border-bottom: 1px solid var(--header-white-color);
  }

  .header-pos-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .header-container {
    background: none;
    border-bottom: none;
    padding: 24px 88px;
  }

  .igor {
    letter-spacing: .64px;
    font-size: 32px;
    font-weight: 700;
  }

  .line-left {
    width: 110px;
  }

  .label {
    letter-spacing: 3.84px;
    font-size: 16px;
    line-height: 140%;
  }

  .line-right {
    width: 59px;
  }

  .nav-links {
    gap: 8px;
    display: flex;
  }

  .nav-links li {
    padding: 4px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .nav-links li a {
    color: var(--caption-grey);
  }

  .header-btn {
    border: 1px solid var(--orange-button);
    color: var(--header-white-color);
    background-color: #0000;
    border-radius: 8px;
    padding: 10px 30px;
  }

  .header-menu_link {
    text-decoration: none;
    position: relative;
  }

  .header-menu_link:after {
    content: "";
    background-color: var(--caption-grey);
    opacity: 0;
    width: 100%;
    height: 2px;
    transition: transform .4s ease-out;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: scaleX(0);
  }

  .header-menu_link:hover:after {
    opacity: 1;
    transform: scaleX(1);
  }

  .header-menu_link:hover {
    font-weight: 700;
  }

  main {
    padding-top: 115px;
  }

  .main-slogan {
    background-image: linear-gradient(0deg, #02020266 1.01%, #b1aaaa66 101.01%), url("pc-main-slogan.725c66ae.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 36.95vw;
    min-height: 729px;
  }

  .main-slogan-container {
    background-image: none;
    max-width: 1280px;
    margin: 0 auto;
    display: block;
  }

  .position-container {
    top: 10.61vw;
  }

  .slogan-text {
    width: 540px;
  }

  .slogan-text h1 {
    width: 540px;
    margin-bottom: 28px;
    font-size: 32px;
  }

  .slogan-text h3 {
    width: 479px;
    font-size: 24px;
  }

  .contact-slogan-text {
    align-items: center;
    margin-top: 10.57vw;
  }

  .contact-slogan-btn {
    width: 308px;
    padding: 14px;
    font-size: 20px;
  }

  .main-slogan-text {
    align-items: flex-start;
  }

  .order-btn {
    width: 308px;
    padding: 14px;
    font-size: 20px;
  }

  .hero-section {
    background: var(--brown-gradient);
  }

  .hero-pos-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .hero-container {
    background: none;
    justify-content: space-between;
    align-items: center;
    padding: 100px 88px;
  }

  .hero-container .hero-details {
    width: 541px;
  }

  .hero-container img {
    border-radius: 4px;
    width: 446px;
    height: 612px;
  }

  .hero-name {
    margin-top: 0;
    margin-bottom: 84px;
  }

  .hero-name h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
  }

  .hero-name h1 {
    font-size: 32px;
  }

  .tab-pc {
    margin-top: 0;
  }

  .tab-pc p {
    font-size: 20px;
  }

  .achievements-section {
    background: var(--achievements-background-color);
  }

  .achievements-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 45px;
  }

  .achievements {
    justify-content: space-evenly;
  }

  .up-text {
    font-size: 24px;
  }

  .areas-section {
    background: var(--brown-gradient);
  }

  .areas-section-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
  }

  .areas-section-container h1 {
    margin-bottom: 34px;
    font-size: 32px;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(calc(33.3333% - 90.6667px), 1fr));
    gap: 24px;
  }

  .areas-section-container .main-card-image, .areas-section-container .card-image {
    height: 250px;
  }

  .areas-section-container .caption {
    height: 70px;
    padding: 20px 8px;
  }

  .areas-section-container .caption img {
    width: 30px;
    height: 30px;
  }

  .areas-section-container .caption h3 {
    font-size: 20px;
  }

  .product-card:hover .card-image {
    transform: scale(1.1);
  }

  .contacts-address-section {
    background: var(--feedback-background-color);
  }

  .contacts-main-slogan {
    background-image: linear-gradient(#b1aaaa66 -1.01%, #02020266 98.99%), url("pc-menu-2x.f8f6105d.webp");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 36.95vw;
    min-height: 742px;
  }

  .contacts-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
  }

  .contact-slogan-text {
    margin-bottom: 80px;
  }

  .contact-slogan-text h1 {
    margin-bottom: 28px;
    font-size: 32px;
  }

  .contact-slogan-text h3 {
    font-size: 24px;
  }

  .order-address-flex-container {
    flex-direction: row-reverse;
    justify-content: space-around;
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px 100px 0;
    display: flex;
  }

  .order-address-flex-container .adress-section-container {
    background: none;
    width: 749px;
    padding: 0;
  }

  .order-address-flex-container .adress-section-container h2 {
    margin-bottom: 34px;
    font-size: 32px;
  }

  .order-address-flex-container .adress-section-container .tel-number a {
    font-size: 20px;
  }

  .order-address-flex-container .adress-section-container .tel-number a svg {
    width: 20px;
    height: 20px;
  }

  .order-address-flex-container .adress-section-container .mail a {
    font-size: 20px;
  }

  .order-address-flex-container .adress-section-container .mail svg {
    width: 22px;
    height: 17px;
  }

  .order-address-flex-container .adress-section-container .adress {
    margin-bottom: 20px;
  }

  .order-address-flex-container .adress-section-container .adress svg {
    width: 22px;
    height: 24px;
  }

  .order-address-flex-container .adress-section-container .adress p {
    font-size: 20px;
  }

  .order-address-flex-container .adress-section-container .map {
    height: 805px;
  }

  .order-address-flex-container .page-order-section {
    display: block;
  }

  .order-address-flex-container .order-image {
    background-color: #0000;
    background-image: linear-gradient(0deg, #b9b9b91a 0% 100%), url("igor-main-order-image_pc_2x.aad25a3f.webp");
    background-position: 0 0, center;
    background-repeat: repeat, no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    border-radius: 4px;
    width: 419px;
    height: 391px;
  }

  .order-address-flex-container .order-section-container {
    background: none;
    padding: 0;
  }

  .order-address-flex-container .order-section-container img {
    height: 391px;
  }

  .order-address-flex-container .order-section-container .order-main {
    width: 419px;
    padding: 48px 16px 0;
  }

  .order-address-flex-container .order-section-container .order-main > h2 {
    font-size: 24px;
  }

  .order-address-flex-container .order-section-container .order-main > h3 {
    width: 387px;
    font-size: 20px;
  }

  .order-address-flex-container .form-label {
    font-size: 20px;
  }

  .order-address-flex-container .form-input {
    font-size: 16px;
  }

  .order-address-flex-container .btn-submit {
    align-items: center;
    width: 247px;
    font-size: 20px;
  }

  .order-address-flex-container .btn-submit .btn-icon {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }

  .order-address-flex-container .main-socials {
    width: 419px;
    padding: 8px 16px 32px;
  }

  .order-address-flex-container .main-socials .social-container {
    flex-direction: column;
  }

  .order-address-flex-container .main-socials h3 {
    font-size: 20px;
  }

  .order-address-flex-container .main-socials .main-social-icons {
    justify-content: space-between;
    width: 339px;
  }

  .order-address-flex-container .gap-pc-up {
    gap: 120px;
  }

  .order-address-flex-container .gap-pc-low {
    gap: 80px;
  }

  .order-address-flex-container .main-social-icon {
    width: 30px;
    height: 30px;
  }

  .order-address-flex-container .main-social-icons li .social-icon-block > a {
    gap: 8px;
  }

  .order-address-flex-container .social-title {
    font-size: 20px;
  }

  .footer-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 88px 10px;
  }

  .list-mobile_nav {
    flex-direction: column;
    gap: 12px;
    display: flex;
  }

  .mobile-menu_link {
    font-size: 16px;
  }

  .logo-slogan {
    margin-top: 28px;
    font-size: 20px;
  }

  .s-icon {
    width: 20px;
    height: 20px;
  }

  .all-rights {
    gap: 50px;
    margin-left: 10px;
  }

  .all-rights h3 {
    font-size: 15px;
  }

  .modal-form {
    max-width: 882px;
  }

  #padding-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header-modal-container h2 {
    font-weight: 400;
  }

  .modal-flex-container {
    justify-content: space-between;
    padding: 20px 32px;
  }

  .modal-flex-container img {
    display: none;
  }

  .modal-pc-img {
    background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url("modal-order-igor_PC_2x.a4b4f054.webp") 50% / cover no-repeat;
    border-radius: 4px;
    width: 291px;
  }

  .modal-block-container {
    width: 509px;
  }

  .modal-form h3 {
    font-size: 20px;
  }

  .form-label {
    font-weight: 400;
  }

  .input-modal-name, .input-modal-tel, #wrightme {
    font-size: 16px;
  }

  .main-socials.modal-form {
    padding: 12px 20px;
  }

  .modal-social-icon {
    width: 30px;
    height: 30px;
  }

  .social-title-modal {
    font-size: 16px;
  }

  .main-social-icons li .social-icon-block > a {
    gap: 8px;
  }

  .modal-submit-btn {
    width: 247px;
    margin: 0;
    font-size: 20px;
  }

  .thanks-section-container {
    width: 933px;
  }

  .thanks-header-modal svg {
    width: 24px;
    height: 24px;
  }

  .thanks-flex-container {
    gap: 18px;
    padding: 20px 32px;
  }

  .thanks-flex-container .thanks-modal-img {
    background: linear-gradient(0deg, #9fa3aa33 0% 100%), #d3d3d3 url("thanks-modal-pc_2x.7ee02ead.webp") 50% / cover no-repeat;
    width: 342px;
    height: 346px;
  }

  .thanks-flex-container .thanks-main-modal {
    width: 509px;
  }

  .thanks-flex-container .thanks-main-modal h1 {
    font-size: 24px;
  }

  .thanks-flex-container .thanks-main-modal .thanks-main-modal-text p {
    font-size: 20px;
  }

  .thanks-flex-container .thanks-main-modal-socials {
    padding: 12px 20px;
  }

  .thanks-flex-container .thanks-main-modal-socials h3 {
    font-size: 16px;
  }

  .thanks-flex-container .thanks-main-modal-socials ul {
    margin-top: 24px;
  }

  .mystory-section-pc {
    background: var(--feedback-background-color);
    display: block;
  }

  .mystory-section {
    display: none;
  }

  .mystory-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
  }

  .mystory-container .hero-name {
    margin-bottom: 34px;
  }

  .mystory-container .hero-name h3 {
    font-size: 20px;
    font-weight: 500;
  }

  #flex-text {
    max-width: 634px;
  }

  .mystory-section-text p {
    font-size: 20px;
    font-weight: 400;
  }

  .mystory-section-text p:not(:last-child) {
    margin-bottom: 18.5px;
  }

  .mystory-section-text p:last-child {
    margin-bottom: 0;
  }

  .aboutme-flex-text-container {
    gap: 24px;
    display: flex;
  }

  .aboutme-text-container {
    width: 634px;
    display: block;
  }

  .aboutme-img {
    background: #d3d3d3 url("mystory-pc_2x.0c5702cf.webp") center / cover no-repeat;
    width: 446px;
    height: 672px;
  }

  #mystory-slogan-text {
    margin-top: 34px;
    margin-bottom: 34px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  .order-btn-aboutme {
    margin: 0 auto 0 0;
  }

  .whyme-section {
    background: var(--select-bg-color);
  }

  .whyme-section-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 60px 88px;
  }

  .whyme-section-container h1 {
    margin-bottom: 34px;
    font-size: 32px;
  }

  .whyme-flex-container {
    gap: 32px;
  }

  .online-support img {
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
  }

  .online-support h4 {
    margin-bottom: 12px;
    font-size: 20px;
  }

  .online-support p {
    font-size: 20px;
  }

  .order-form-display-container {
    display: none;
  }

  .main-order-section-message-pc {
    background: var(--feedback-background-color);
    display: block;
  }

  .section-message-pc-container {
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
    display: flex;
  }

  .message-pc-block-container {
    display: block;
  }

  .message-pc-block-container .order-igor-tab-img {
    background: linear-gradient(0deg, #9fa3aa1a 0% 100%), #d3d3d3 url("order-igor-pc_2x.1805cd4c.webp") 50% / cover no-repeat;
    border-radius: 4px;
    width: 447px;
    height: 402px;
    margin: 0 auto;
  }

  .page-form-socials {
    margin-top: 32px;
  }

  .main-socials h3 {
    color: var(--black-secondary-text-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
  }

  .main-social-icon {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
  }

  .page-form-social-title {
    font-size: 20px;
    font-weight: 400;
  }

  .form-container {
    padding: 24px 16px;
  }

  .form-container .page-form-title {
    color: var(--black-secondary-text-color);
  }

  .form-container .page-form-title h2 {
    margin-bottom: 12px;
    font-family: Merriweather;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }

  .form-container .page-form-title h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
  }

  .feedback-form {
    margin-top: 40px;
  }

  .feedback-form .form-order-form {
    width: auto;
  }

  .page-form-label {
    font-size: 20px;
    font-weight: 500;
  }

  .input-name, .input-tel, .input-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }

  .fixed-height {
    resize: none;
    overflow: hidden;
  }

  .page-form-btn {
    color: var(--black-button-color);
    width: 247px;
    margin: 34px auto 0;
    font-size: 20px;
  }

  .service-types-pc-gradient-container {
    background: var(--main-order-gradient);
  }

  .service-types-section-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px 0;
  }

  .service-types-section-container h2 {
    font-size: 32px;
  }

  .service-types-section-container p {
    width: 851px;
    margin-bottom: 0;
    font-size: 24px;
  }

  .migration-serv-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 88px 100px;
  }

  .service-img-flex-container {
    flex-direction: row;
    gap: 24px;
    margin-top: 34px;
    display: flex;
  }

  .service-img-flex-container .service-title {
    justify-content: space-between;
    width: 728px;
  }

  .service-img-flex-container .service-title .service-title-flex-container {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }

  .service-img-flex-container .service-title .service-title-flex-container img {
    width: 80px;
    height: 80px;
  }

  .service-img-flex-container .service-title .service-title-flex-container h1 {
    font-family: Merriweather;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .service-img-flex-container .service-title .services-btn {
    width: auto;
    margin: 0 auto 0 0;
    font-size: 20px;
  }

  .services-btn:hover {
    background: var(--button-hover-gradient);
    color: var(--header-white-color);
    cursor: pointer;
  }

  .services-btn:active {
    background: var(--button-active-gradient);
    color: var(--header-white-color);
  }

  .migration-serv-container article {
    color: var(--black-secondary-text-color);
    margin: 0;
  }

  .migration-serv-container article p.migtation-descr {
    margin-top: 44px;
    margin-bottom: 44px;
    font-size: 20px;
  }

  .migration-serv-container article p.tax-descr {
    margin-top: 58px;
    margin-bottom: 58px;
    font-size: 20px;
  }

  .migration-serv-container article p.criminal-descr {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
  }

  .migration-serv-container article p.criminal-descr .criminal-descr-first-line {
    margin-bottom: 10px;
    display: block;
  }

  .migration-serv-container article p.fintech-descr, .migration-serv-container article p.military-descr {
    font-size: 20px;
  }

  .migration-serv-container article p.military-descr .military-descr-first-line {
    margin-bottom: 10px;
    display: block;
  }

  .migration-serv-container article p.civil-descr, .migration-serv-container article p.admin-descr, .migration-serv-container article p.intellectual-descr {
    font-size: 20px;
  }

  .migration-serv-container article p.intellectual-descr .intellectual-descr-first-line {
    margin-bottom: 10px;
    display: block;
  }

  .migration-serv-container article p.debt-rec-descr, .migration-serv-container article p.deb-pro-descr, .migration-serv-container article p.labor-descr, .migration-serv-container article p.business-descr {
    font-size: 20px;
  }

  .side-buttons, .criminal-img, .fintech-img, .tax-img, .civil-img, .admin-img, .intellectual-img, .military-img, .labor-img, .debt-recovery-img, .debtor-protection-img, .business-law-img, .migration-img {
    display: none;
  }

  .service-types-img-pc {
    border-radius: 4px;
    width: 352px;
    height: 400px;
    display: block;
  }

  .migration-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("migration-image_2x.d4bb24ce.webp") 50% / cover no-repeat;
  }

  .criminal-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("criminal-law-pc_2x.1c1898e6.webp") 50% / cover no-repeat;
  }

  .tax-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("tax-image_2x.05238fe4.webp") 50% / cover no-repeat;
  }

  .fintech-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("fintech-image_2x.83a06d11.webp") 50% / cover no-repeat;
  }

  .civil-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("civil-image_2x.7ba7ef71.webp") 50% / cover no-repeat;
  }

  .admin-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("admin-image_2x.d78b2cb9.webp") 50% / cover no-repeat;
  }

  .intellectual-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("intellectual-image_2x.196af18b.webp") 50% / cover no-repeat;
  }

  .military-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("military-image_2x.ee8dd33d.webp") 50% / cover no-repeat;
  }

  .debt-rec-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("debt-rec-image_2x.e3b1be25.webp") 50% / cover no-repeat;
  }

  .deb-pro-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("deb-pro-image_2x.238b347f.webp") 50% / cover no-repeat;
  }

  .labor-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("labor-image_2x.f6bfcde0.webp") 50% / cover no-repeat;
  }

  .business-img-pc {
    background: linear-gradient(0deg, #877d7d66 0% 100%), #d3d3d3 url("business-image_2x.fecd55f0.webp") 50% / cover no-repeat;
  }

  .intel-list {
    margin-bottom: 24px;
  }

  .intel-list h2 {
    font-family: Roboto;
    font-size: 20px;
  }

  .intel-list .intel-title li {
    font-size: 16px;
  }

  .business-whyme-title, .labor-whyme-title, .admin-pc-whyme-title, .debt-rec-pc-whyme-title {
    width: 728px;
  }

  .business-whyme-title h2, .labor-whyme-title h2, .admin-pc-whyme-title h2, .debt-rec-pc-whyme-title h2 {
    color: var(--black-secondary-text-color);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
  }

  .business-whyme-title p, .labor-whyme-title p, .admin-pc-whyme-title p, .debt-rec-pc-whyme-title p {
    font-size: 16px;
  }

  .side-buttons-pc-flex-container {
    display: block;
  }

  .service-type-pc-flex-container {
    gap: 24px;
    display: flex;
  }

  .labor-pc-service-title-container, .admin-pc-service-title-container, .debt-rec-pc-service-title-container {
    flex-direction: column;
    display: flex;
  }

  .labor-pc-service-title-container .pc-column-service-items, .admin-pc-service-title-container .pc-column-service-items, .debt-rec-pc-service-title-container .pc-column-service-items {
    margin-bottom: -18px;
  }

  .labor-pc-service-title-container .pc-column-service-items .service-item p, .admin-pc-service-title-container .pc-column-service-items .service-item p, .debt-rec-pc-service-title-container .pc-column-service-items .service-item p {
    font-size: 16px;
  }

  .labor-pc-service-title-container .pc-column-service-items .service-item .service-details h3, .admin-pc-service-title-container .pc-column-service-items .service-item .service-details h3, .debt-rec-pc-service-title-container .pc-column-service-items .service-item .service-details h3 {
    width: 696px;
    font-size: 16px;
  }

  .military-service-details {
    align-items: flex-start;
    margin-bottom: 0;
  }

  .pc-service-title-container {
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }

  .pc-service-title-container .pc-column-service-items {
    flex-direction: column;
    gap: 24px;
    max-width: 410px;
    display: flex;
  }

  .pc-service-title-container .pc-column-service-items .service-item {
    min-height: 120px;
    margin: 0;
  }

  .pc-service-title-container .pc-column-service-items .service-item .service-details h3 {
    width: 320px;
    font-size: 16px;
    line-height: 140%;
  }

  .military-service-item {
    min-height: 88px !important;
  }

  .deb-pro-service-details {
    align-items: flex-start;
    margin-bottom: 0;
  }

  .deb-pro-service-item {
    min-height: 38px !important;
  }

  .side-service-buttons-container .service-buttons li {
    width: 352px;
    padding: 20px 10px;
  }

  .side-service-buttons-container .service-buttons li.select {
    background: var(--select-bg-color);
  }

  .side-service-buttons-container .service-buttons li.select a {
    text-decoration: underline;
  }

  .service-buttons li a {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .btn-title {
    flex-direction: row;
    gap: 8px;
    display: flex;
  }

  .btn-title img {
    width: 30px;
    height: 30px;
    margin: 0;
    display: block;
  }

  .btn-title h3 {
    font-size: 20px;
  }

  .debt-rec-pc-whyme-title h2, .whyme-title h2 {
    color: var(--black-secondary-text-color);
    font-family: Roboto;
    font-size: 20px;
  }

  .debt-rec-pc-whyme-title p, .whyme-title p {
    font-size: 16px;
  }

  .main-order-section {
    display: none;
  }

  .modal-order-window {
    display: block;
  }

  .main-order-section-pc {
    background: var(--select-bg-color);
    display: block;
  }

  .section-pc-container {
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
    display: flex;
  }

  .service-item-bottom-text {
    font-size: 16px;
  }

  .labor-bottom-text, .admin-bottom-text, .debt-rec-pc-bottom-text {
    margin-top: 18px;
  }

  .cases {
    padding: 0;
  }

  .cases .cases-content {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
  }

  .steps-section {
    background: var(--select-bg-color);
  }

  .steps-section .steps-section-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
  }

  .cases-about {
    color: var(--black-secondary-text-color);
    margin-bottom: 34px;
  }

  .cases-about h1 {
    font-size: 32px;
  }

  .cases-about p {
    width: 704px;
    font-size: 20px;
  }

  .case-card-pc-module:not(:last-child), .card-flex-pc-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .height-img {
    height: 236px;
    overflow: hidden;
  }

  .card-image, .main-card-image {
    transition: transform var(--transition-speed);
  }

  .main-card-image:hover, .card-image:hover {
    transform: scale(1.15);
  }

  .wide-image {
    max-width: 712px;
  }

  .card-image-pc-container {
    cursor: pointer;
    transition: all var(--transition-speed);
  }

  .narrow-image {
    width: 0;
  }

  .wide-image:hover, .narrow-image:hover {
    cursor: pointer;
  }

  .caption {
    height: 84px;
    padding: 8px;
  }

  .case-caption {
    font-size: 20px;
  }

  .card-flex-pc-container {
    position: relative;
  }

  .case-description-container {
    z-index: 1;
    width: 1104px;
    margin-left: auto;
    margin-right: auto;
    transition: transform .5s, opacity .5s;
    position: relative;
    transform: translateX(0);
  }

  .case-description-container .case-card .case-flex-container-left {
    width: 728px;
  }

  .case-description-container .case-card .case-flex-container-left .case-description h2 {
    font-size: 24px;
  }

  .case-description-container .case-card .case-flex-container-left .case-description h3 {
    font-size: 20px;
  }

  .case-description-container .case-card .case-flex-container-left .case-description p, .case-description-container .case-card .case-flex-container-left .case-description ol > li {
    font-size: 16px;
  }

  .case-description-container .case-card .case-flex-container-right {
    width: 352px;
  }

  .case-description-container .case-card .case-flex-container-right h3 {
    font-size: 20px;
  }

  .case-description-container .case-card .case-flex-container-right ul > li, .case-description-container .case-card .case-flex-container-right p {
    font-size: 16px;
  }

  .case-description-container .cases-btn {
    width: 352px;
  }

  .four-steps h2 {
    font-size: 32px;
  }

  .four-steps p {
    width: 703px;
    font-size: 20px;
  }

  .steps-container .first-step {
    padding: 8px 4px;
  }

  .steps-container .first-step .step {
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
    font-size: 20px;
  }

  .steps-container .first-step h3 {
    margin-bottom: 12px;
    font-size: 20px;
  }

  .steps-container .first-step .step-details li {
    font-size: 20px;
  }

  .feedback-slider-section, .background-container {
    background: var(--feedback-background-color);
  }

  .feedback-cases-container {
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 88px;
  }

  .feedback-cases-container h2 {
    margin-bottom: 34px;
    font-family: Merriweather;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4;
  }

  .feedback-cases-container .feedback-img-cases {
    background: #d3d3d3 url("feedback_cases_pc_2x_h418.036c38bf.webp") 50% / cover no-repeat;
    border-radius: 4px;
    width: 352px;
    height: 418px;
  }

  .feedback-container {
    background: none;
    max-width: 1280px;
    padding: 100px 88px;
    margin: 0 auto !important;
  }

  .feedback-container .feedback-img {
    background: url("feedback_2x_pc_h418.2a0a9578.webp") center / cover;
    border-radius: 4px;
    height: 418px;
  }

  .feedback-container h2 {
    margin-bottom: 34px;
    font-size: 32px;
  }

  .feedback-container .feedback-img-main {
    background: url("feedback_2x_h265.3d1d36bb.webp") center / cover;
    border-radius: 4px;
    height: 418px;
  }

  .cases-feedback-slider {
    margin-top: 0;
  }

  .slick-slide {
    margin-left: 10px;
  }

  .slider-slide {
    height: 416px;
    padding: 20px;
    width: 352px !important;
  }

  .slider-slide h3 {
    font-size: 24px;
  }

  .stars-mob {
    display: none;
  }

  .stars {
    justify-content: flex-start;
    gap: 4px;
    margin-top: 8px;
    margin-bottom: 20px;
    display: flex;
  }

  .stars > li > svg {
    width: 20px;
    height: 20px;
  }

  .quotes svg {
    width: 8px;
    height: 14px;
  }

  .slider-text {
    font-size: 16px;
  }

  .feedback-flex-container {
    gap: 24px;
    display: flex;
  }

  .feedback-flex-container .width-container {
    width: 728px;
  }

  .feedback-flex-container .feedback-slider {
    margin-top: 0;
  }

  .cases-prev-slide svg, .cases-next-slide svg, .main-prev-slide svg, .main-next-slide svg {
    width: 48px;
    height: 48px;
  }

  .slick-arrow :hover {
    cursor: pointer;
  }
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.ca197847.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Bold.fdb9b54a.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Merriweather;
  src: url("Merriweather-Regular.574fb52b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Regular.52ca3563.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=aboutme.4ab33dc5.css.map */
