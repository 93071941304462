@media screen and (min-width: 1280px) {
  .modal-form {
    max-width: 882px;
    // margin: 0 auto;

  }
  #padding-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header-modal-container {
    h2 {
      font-weight: 400;
    }
  }

  .modal-flex-container{
    justify-content: space-between;
    padding: 20px 32px;
    img{
        display:none;
    }
  }
  .modal-pc-img {
    // height: 402px;
    width: 291px;
    border-radius: 4px;
    background: linear-gradient(
        0deg,
        rgba(159, 163, 170, 0.2) 0%,
        rgba(159, 163, 170, 0.2) 100%
      ),
      url('../images/modal-order-igor_PC_2x.webp') lightgray 50% / cover
        no-repeat;
  }
.modal-block-container{
    width: 509px;
}

.modal-form{
    h3{
        font-size: 20px;
    }
}

.form-label{
    font-weight: 400;
}
.input-modal-name,
.input-modal-tel{
    font-size: 16px;
}
#wrightme {
    font-size: 16px;  
}
    .main-socials.modal-form {
    padding: 12px 20px;
}
.modal-social-icon{
    width: 30px;
height: 30px;
}
.social-title-modal{
    font-size: 16px;
}
.main-social-icons li .social-icon-block > a {
    gap:8px;
}
.modal-submit-btn {
    width: 247px;
    margin: 0;
    font-size: 20px;
}

// .btn-icon{
//     width: 20px;
//     height: 20px;
// }
  //thanks modal
  .thanks-section-container{
    width: 933px;

  }
  .thanks-header-modal{
    svg{
      width: 24px;
      height: 24px;
    }
  }
  .thanks-flex-container {
    padding: 20px 32px;
    gap: 18px;
    .thanks-modal-img {
      width: 342px;
      height: 346px;
      background: linear-gradient(
        0deg,
        rgba(159, 163, 170, 0.2) 0%,
        rgba(159, 163, 170, 0.2) 100%
      ),
      url('../images/thanks-modal-pc_2x.webp') lightgray 50% / cover
        no-repeat;
    }
    .thanks-main-modal {
      width: 509px;
      h1{
        font-size: 24px;
      }
      .thanks-main-modal-text {
        p {
          font-size: 20px;
        }
      }
    }
    .thanks-main-modal-socials {
      padding: 12px 20px;
        h3{
          font-size: 16px;
        }
        ul{
          margin-top:24px;
        }
    }

  }




}
