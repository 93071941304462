.main-slogan-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 120px;
  height: 478px;
  background: linear-gradient(
      0deg,
      rgba(2, 2, 2, 0.4) 1.01%,
      rgba(177, 170, 170, 0.4) 101.01%
    ),

      url('../images/mob-main-slogan.webp');

  background-size: cover; /* Полностью покрывает область */
  background-position: center; /* Центрируем изображение */
}
.position-container{
  display: flex;
  flex-direction: column;
  gap: 120px;
  align-items: center;
}
.contacts-container{
  background: linear-gradient(0deg, rgba(220, 218, 216, 0.30) 0%, rgba(220, 218, 216, 0.30) 100%),

    url('../images/mob-menu-w393.webp') lightgray 50% / cover no-repeat;
    height: 546px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: inline-flex;

}



.slogan-text h1 {
  color: var(--header-white-color);
  text-align: center;
  font-family: 'Merriweather';
  font-size: 18px;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 40px;
  width: 306px;
}
.slogan-text h3 {
  color: var(--header-white-color);
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; /* 16.8px */
  width: 278px;
}

.slogan-text {
  width: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.slogan-order-btn {
  width: 278px;
  color: var(--button-white-color);
  font-weight: 700;
  line-height: 1.4;
}

.contact-slogan-text{
  justify-content: flex-start;
  margin-top: 100px;
  margin-bottom: 101px;
  
  h1{
    margin-bottom: 28px;
    width: 236px;
    color:var(--black-main-text-color);
  }
  h3{
    color:var(--black-main-text-color);
  }

}

.contact-slogan-btn{
  padding: 14px 30px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--Bronze-Button, #B4721D);
      background: var(--Bronze-Button, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #B4721D);
      margin: 0px 14px;
      color: var( --header-white-color);
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 120px;
}


// hero-section
.hero-container {
  background: var(--brown-gradient);
  padding: 40px 16px;
}
.hero-name {
  color: var(--header-white-color);
  font-family: 'Merriweather';
  line-height: 1.4;
  margin-bottom: 16px;
}
.hero-name h2 {
  font-size: 14px;
  margin-bottom: 8px;
}
.hero-name h1 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.hero-container img {
  border-radius: 4px;
  width: 100%;
}

.hero-section-text {
  margin-top: 24px;
}
.hero-section-text p {
  color: var(--header-white-color);
  font-family: 'Roboto';
  line-height: 1.4;
}
.tab-pc{
display: none;
}

// achievements-section
.achievements-container {
  background: var(--achievements-background-color);
  padding: 40px 16px;
}
.achievements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.achievements li {
  text-align: center;
}
.up-text {
  display: flex;
    flex-direction: column;
    gap:15px;
  color: #0d0d0e;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.bot-text {
  font-family: 'Roboto';
  font-weight: 600;
}
#ach-line {
  width: 1px;
  height: 40px;
  transform: rotate(90deg);
  border-radius: 1px;
  border-color: #0d0d0e;
  // border-style: solid;
}
// areas-section

.areas-section-container {
  padding: 40px 16px;
  background: var(--brown-gradient);
}
.areas-section-container h1 {
  margin-bottom: 24px;
  color: var(--header-white-color);
  font-family: 'Merriweather';
  font-size: 18px;
  line-height: 1.4;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc((100% - 32px - 32px) / 2), 1fr)
  ); /* Для 2 колонок на экране шириной 320px */
  column-gap: 16px;
  gap: 12px; /* Вертик Расстояние между карточками */
}

.product-card {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.main-card-image,
.card-image {
  width: 100%;
  height: 94px; /* Указываем высоту картинки */
  // overflow-y: hidden;
  transition: transform 0.5s ease; /* Плавный переход для эффекта zoom */
}

.overflow-container{
  overflow: hidden;
}
.main-card-image:hover{
  transform: scale(1.2);
  cursor: pointer;
 
}

.image-1 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-2_352x234.webp') lightgray 50% / cover no-repeat;
}

.image-2 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/admin-practice_w363.webp') lightgray 50% / cover no-repeat;
}
.image-3 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-3_352x234.webp') lightgray 50% / cover no-repeat;
}
.image-4 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-4_352x234.webp') lightgray 50% / cover no-repeat;
}
.image-5 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-1_352x234.webp') lightgray 50% / cover no-repeat;
}
.image-6 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/tax-law.webp') lightgray 50% / cover no-repeat;
}

.image-7 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-7_352x234.webp') lightgray 50% / cover no-repeat;
}
.image-8 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/area-8_352x234.webp') lightgray 50% / cover no-repeat;
}

.image-9 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/fintech.webp') lightgray 50% / cover no-repeat;
}
.image-10 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/debt-recovery.webp') lightgray 50% / cover no-repeat;
}
.image-11 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/debtor-protection.webp') lightgray 50% / cover no-repeat;
}
.image-12 {
  background: linear-gradient(
      0deg,
      rgba(135, 125, 125, 0.4) 0%,
      rgba(135, 125, 125, 0.4) 100%
    ),
    url('../images/labor-law.webp') lightgray 50% / cover no-repeat;
}

.product-card:hover .card-image {
  transform: scale(1.15);
}

.caption {
  padding: 6px 2px;

  text-align: center;
  height: 42px;
  align-content: center;
  background:var(--caption-grey);
  img{
    display:none;
  }
  
  h3{
    color:var(--black-secondary-text-color);
    font-family: 'Merriweather';
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
  }
  h3:hover{
    color: #875E2A;
  }
}


// order-section
// .main-order-section{
//   display:none;
// }
.order-section-container {
  padding: 50px 0px 0px 0px;
  background: var(--main-order-gradient);
}
.order-image{
  height: 388px;
  width:100%;
  border-radius: 4px;
  background: linear-gradient(
        0deg,
        rgba(185, 185, 185, 0.10) 0%,
        rgba(185, 185, 185, 0.10) 100%
    ), 
    url('../images/igor-2_w393.webp') no-repeat center;
    background-size: cover;

}

.order-section-container img {
  // height: 388px;
  width: 100%;
  margin: 0 auto;
}
.contacts-order-section{
  display:none;
}
.order-main{
  padding: 20px 15px 0px 15px;
  background: var(--header-white-color);
}
.call-to-action{
  padding: 0px 15px;
}

.order-main,
.call-to-action{

 
  h2{
    font-family: 'Merriweather';
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 8px;
  }
  h3{
    color: var(--black-secondary-text-color);
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}
.contacts{
  padding: 0;
}

//order-form

.order-slogan,
.order-form {
  display: flex;
  padding: 20px 15px;
  flex-direction: column;

  background: var(--header-white-color);
}
.order-slogan{
  background:none;
}

.order-slogan h2,
.order-form h2 {
  font-family: 'Merriweather';
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  color: var(--black-main-text-color);
  margin-bottom: 8px;
}

.order-slogan h3,
.order-form h3 {
  color: var(--black-secondary-text-color);
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4; /* 19.6px */
  margin-bottom: 20px;
}
.order-slogan h3{
  margin-bottom: 0px;
}

.form,
.form-label {
  display: flex;
  flex-direction: column;
}

.form-label {
  color: var(--black-main-text-color);
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4; /* 16.8px */
}

.form-input {
  border-bottom: 1px solid #000;
  padding: 10px 8px;
  border-left: none;
  border-top: none;
  border-right: none;
  background-color: transparent;
}
.input-tel,
.input-name {
  margin-bottom: 8px;
}

.btn-submit {
  display: flex;
  align-items: center;
  padding: 14px 40px;
  justify-content: center;
  border-radius: 8px;
  border: 3px solid var(--bronze-button);
  margin: 20px auto;
  color: var(--black-button-color);
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4; /* 19.6px */
}

.btn-icon{
  width: 16px;
height: 16px;
margin-left: 10px;
}

//main-socials
.main-socials{
  padding:0px 15px 20px 15px;
  background: var(--header-white-color);
  .social-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.main-social-icons{
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  gap: 9.375vw;
}
.social-icon-block a{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

}
.main-social-icon{
  width: 20px;
height: 20px;
}

.social-title{
color:var(--black-secondary-text-color);
font-family: 'Roboto';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 1.4; /* 16.8px */
}

//adress-section
.adress-section-container{
  padding: 24px 0px 10px 0px;
  background: var(--feedback-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.contact-container{
  padding: 48px 0px 10px 0px;
}

.adress-section-container h2{
  color:var(--black-main-text-color);
  text-align: center;
font-family: 'Merriweather';
font-size: 18px;
line-height: 1.4; 
}

.adress,
.tel-number,
.mail{
  display:flex;

}
.adress p,
.tel-number a,
.mail a{
color:var(--black-main-text-color);
font-family: 'Roboto';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 1.4; /* 16.8px */
}
.tel-number svg{
  width: 16px;
height: 16px;
}


.adress svg,
.tel-number svg,
.mail svg{
  margin-right:4px;
}

.map{
  width: 100%;
  height: 200px;
  border: none;

}
.adress>nav>a{
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  align-items: center;
}

.no-scroll {
  overflow: hidden;
}
