.cases{
    padding: 70px 15px;
    background: var( --main-order-gradient);
}

.cases-about{
    line-height:1.4;
    margin-bottom: 24px;
}
.cases-about h1{
color: var(--black-main-text-color);
font-family: 'Merriweather';
font-size: 18px;
font-weight: 700;
margin-bottom: 8px;
}

.cases-about p{
font-family: 'Roboto';
font-size: 14px;
font-weight: 400;
}

.cases-pc-container{
    display:none;
}
// .case-description-container{
//     transition: transform var(--transition-speed), opacity var(--transition-speed);
//     opacity: 0;
//     transform: scaleY(0);
// }
// .case-description-container:not(.is-hidden) {
//     opacity: 1;
//     transform: scaleY(1);
//   }

.case-description{
    line-height:1.4;
    color: var(--black-main-text-color);
    margin-top:10px;
}
.case-description h2{
    font-family: 'Merriweather';
font-size: 13px;
font-weight: 700;
}

.adm-law{
    margin-top: 8px;
    margin-bottom:8px;
}

.case-idea{
    margin-bottom:12px;
}

.resolution{
    margin-bottom:4px;
}
.case-context{
    margin-bottom:16px;
}

.case-description h3{
    font-family: 'Roboto';
font-size: 12px;
font-weight: 700;
}
.case-description p{
    font-weight: 400;
    font-size: 12px;
}

.case-description ol,
.case-description ul{
    padding: 0px 15px;
}

.case-description ol>li,
.case-description ul>li{
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
    list-style-type: disc ;
}

.case-flex-container-right{
    ul{
        padding-left: 15px;
    }
    ul>li{
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        list-style-type: disc;
    }
}
.case-description ol>li{
    list-style-type: decimal;
}
.main-idea{
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 12px;
}
.cases-btn{
    margin-top:12px;
    margin-bottom:10px;
}
.main-card-image,
.card-image{
    display: block;
    transition: transform var(--transition-speed);
    width: 100%;
      object-fit: cover;
}

.card-image:hover{
    transform: scale(1.2);
    cursor:pointer;
 }
/* Анимация для карточек */
.card-image-container {
    transition: transform var(--transition-speed);
  }

.case-card-module{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    column-gap: 15px;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* Анимация для появления/скрытия описания */
// .case-card {
//     opacity: 0;
//     max-height: 0;
//     overflow: hidden;
//     transition: max-height var(--transition-speed) ease-in-out, opacity var(--transition-speed) ease-in-out;
//   }

//   .case-card :not(.is-hidden) {
//     opacity: 1;
//     height: auto;
//   }


.wide-image{
    flex-basis: 100%;
    transition: transform var(--transition-speed);
}

.narrow-image{
    flex-basis: calc((100% - 15px) / 2);
       height: 100%;
       transition: transform var(--transition-speed);
}
.height-img{
    height: 122px;
    overflow: hidden;
}
.euro-wide{
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/euro-wide.webp') lightgray 50% /cover no-repeat;
    border-radius: 4px 4px 0px 0px;
    height: 100%;
  }

.euro-narrow{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/euro-narrow.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}

.bispart-narrow{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/bispart-narrow.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}
.bispart-wide{
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/bispart-wide.webp') lightgray 50% / cover no-repeat;
    height: 100%;
}

.cuttingmarriage-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/cuttingmarriage-narrow.webp') lightgray 50% / cover no-repeat;
}
.cuttingmarriage-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/cuttingmarriage-wide.webp') lightgray 50% / cover no-repeat;
}
.justice-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/justice-wide.webp') lightgray 50% / cover no-repeat;
}
.justice-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/justice-narrow.webp') lightgray 50% / cover no-repeat;
}

.criminal-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/criminal-wide.webp') lightgray 50% / cover no-repeat;
}
.criminal-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/criminal-narrow.webp') lightgray 50% / cover no-repeat;
}

.alimenty-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/alimenty-wide.webp') lightgray 50% / cover no-repeat;
}
.alimenty-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/alimenty-narrow.webp') lightgray 50% / cover no-repeat;
}

.land-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/land-wide.webp') lightgray 50% / cover no-repeat;
}

.land-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/land-narrow.webp') lightgray 50% / cover no-repeat;
}
.bayer-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/bayer-wide.webp') lightgray 50% / cover no-repeat;
}
.bayer-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/bayer-narrow.webp') lightgray 50% / cover no-repeat;
}
.custom-wide{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/custom-wide.webp') lightgray 50% / cover no-repeat;
}

.custom-narrow{
    border-radius: 4px 4px 0px 0px;
    height: 100%;
    background: linear-gradient(0deg, rgba(135, 125, 125, 0.40) 0%, rgba(135, 125, 125, 0.40) 100%), url('../images/cases-img/custom-narrow.webp') lightgray 50% / cover no-repeat;
}

.caption {
    color: var(--black-secondary-text-color);
    text-align: center;
    font-family: 'Merriweather';
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;

}

.case-caption{
    border-radius: 0px 0px 4px 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size-adjust: 0.7;
    height: 58px;
    background: var(--light-yellow);
}

.is-hidden{
    display:none;
}
