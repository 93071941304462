@import 'modern-normalize/modern-normalize';
// @import '~node_modules/modern-normalize/modern-normalize.css';
@import './variables';
@import './header';
@import './footer';
@import './main-page';
@import './feedback';
@import './aboutme';
@import './order-form';
@import './service-types';
@import './steps';
@import './cases';
@import './modal-order-window';
//tab styles
@import './tab/header-tab.scss';
@import './tab/main-page-tab';
@import './tab/feedback-tab';
@import './tab/footer-tab';
@import './tab/modal-order-window-tab';
@import './tab/aboutme-tab';
@import './tab/order-form-tab';
@import './tab/service-types-tab';
@import './tab/steps-tab';
@import './tab/cases-tab';
//pc styles
@import './pc/header-pc';
@import './pc/main-page-pc';
@import './pc/footer-pc';
@import './pc/modal-order-window-pc';
@import './pc/aboutme-pc';
@import './pc/service-types-pc';
@import './pc/cases-pc';
@import './pc/feedback-pc';




@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Merriweather';
    src: url('../fonts/Merriweather-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


  